import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

const withCollections = Component => {
  class WithCollections extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }
  const mapStateToProps = state => {
    return { collections: state.posts };
  };

  return compose(
    connect(
      mapStateToProps,
      null
    )
  )(WithCollections);
};

export default withCollections;
