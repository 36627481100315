import React, { Component } from "react";

import { Helmet } from "react-helmet";

class NotFound extends Component {
  render() {
    return (
      <div id="notFound">
        <Helmet>
          <title> Seascape Edition | Not Found</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="offset-1 col-10">
              <div className="notFoundContainer">
                <h1>Error 404</h1>
                <h2>The page you are trying to access doesn't exist!</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
