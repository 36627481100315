import React, { Component } from "react";

import ShareButtons from "../ShareButtons";

class NewsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleImageOpened: false,
      galleryOpened: false,
      index: "",
      singleImageIndex: "",
    };

    this.imageContainer = [];

    this.touchStartX = null;
  }

  componentDidMount() {
    this.setGallery();

    // const twitterScript = document.createElement("script");
    // twitterScript.innerHTML = "twttr.widgets.load()";

    // document.getElementById("wordpressContent").append(twitterScript);
  }

  componentDidUpdate() {
    this.setGallery();

    this.imageContainer = document.getElementsByClassName("imageContainer");

    if (this.imageContainer.length) {
      this.imageContainer[0].addEventListener("touchstart", this.customTouchStart);
      this.imageContainer[0].addEventListener("touchend", this.customTouchEnd);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);

    if (this.imageContainer.length) {
      this.imageContainer[0].removeEventListener("touchstart", this.touchStart);
      this.imageContainer[0].removeEventListener("touchend", this.touchStart);
    }
  }

  customTouchStart = e => {
    this.touchStartX = e.changedTouches[0].pageX;
  };

  customTouchEnd = e => {
    const touchEndX = e.changedTouches[0].pageX;

    if (this.touchStartX < touchEndX) {
      this.setState({
        index: (this.state.index - 1 + this.state.gallery.length) % this.state.gallery.length,
      });
    } else if (this.touchStartX > touchEndX) {
      this.setState({ index: (this.state.index + 1) % this.state.gallery.length });
    }
  };

  setGallery() {
    document.addEventListener("keydown", this.handleKeyDown);

    const galleryImages = document.getElementsByClassName("blocks-gallery-item");
    const gallery = [];

    const singleImages = document.getElementsByClassName("wp-block-image");

    for (let i = 0; i < galleryImages.length; i++) {
      gallery.push(galleryImages[i]);
    }

    const innverWidth = `(100vw - ${document.body.clientWidth}px)`;
    const galleryWidth = `calc(100vw - ${innverWidth})`;
    const galleryPosition = `calc(-50vw + (${innverWidth} / 2) + 50%)`;

    if (gallery.length > 0 && gallery[0].classList[0] === "blocks-gallery-item") {
      const galleries = document.getElementsByClassName("wp-block-gallery");
      for (let i = 0; i < galleries.length; i++) {
        Object.assign(galleries[i].style, {
          width: `${galleryWidth}`,
          left: `${galleryPosition}`,
        });
      }

      for (let i = 0; i < gallery.length; i++) {
        gallery[i].classList.add("galleryImageOverlay");
        gallery[i].firstChild.firstChild.classList.add("galleryImage");
        gallery[i].onclick = () => {
          this.openGallery(gallery, i);
        };
      }
    }

    for (let i = 0; i < singleImages.length; i++) {
      singleImages[i].classList.add("galleryImageOverlay");
      singleImages[i].firstChild.classList.add("galleryImage");
      singleImages[i].onclick = () => {
        this.openSingleImage(singleImages, i);
      };
    }
  }

  handleKeyDown = event => {
    // Esc key
    if ((this.state.galleryOpened || this.state.singleImageOpened) && event.keyCode === 27) {
      document.body.style = "";
      this.setState({ galleryOpened: false, singleImageOpened: false });
    }
    // Left arrow
    if (this.state.galleryOpened && event.keyCode === 37) {
      this.setState({
        index: (this.state.index - 1 + this.state.gallery.length) % this.state.gallery.length,
      });
    }
    // Right arrow
    if (this.state.galleryOpened && event.keyCode === 39) {
      this.setState({ index: (this.state.index + 1) % this.state.gallery.length });
    }
  };

  openGallery(gallery, index) {
    document.body.style = "overflow:hidden;";
    this.setState({ galleryOpened: true, gallery, index });
  }

  openSingleImage(singleImage, singleImageIndex) {
    document.body.style = "overflow:hidden;";
    this.setState({ singleImageOpened: true, singleImage, singleImageIndex });
  }

  gallery(gallery, index) {
    if (this.state.galleryOpened) {
      return (
        <div className="galleryContainer">
          <div
            className="galleryOverlay"
            onClick={() => {
              document.body.style = "";
              this.setState({ galleryOpened: false });
            }}
          />

          <div
            className="prevImageButton"
            onClick={() => {
              this.setState({ index: (index - 1 + gallery.length) % gallery.length });
            }}
          >
            <i className="fas fa-chevron-left iconFont" />
          </div>

          <div
            className="imageContainer"
            dangerouslySetInnerHTML={{ __html: gallery[index].innerHTML }}
          />

          <div
            className="nextImageButton"
            onClick={() => {
              this.setState({ index: (index + 1) % gallery.length });
            }}
          >
            <i className="fas fa-chevron-right iconFont" />
          </div>
          <div className="closeButton">
            <i
              className="fas fa-times fa-2x"
              onClick={() => {
                document.body.style = "";
                this.setState({ galleryOpened: false });
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  singleImageViewer(singleImage, index) {
    if (this.state.singleImageOpened) {
      return (
        <div className="galleryContainer">
          <div
            className="galleryOverlay"
            onClick={() => {
              document.body.style = "";
              this.setState({ singleImageOpened: false });
            }}
          />

          <div
            className="imageContainer"
            dangerouslySetInnerHTML={{ __html: singleImage[index].innerHTML }}
          />

          <div className="closeButton">
            <i
              className="fas fa-times fa-2x"
              onClick={() => {
                document.body.style = "";
                this.setState({ singleImageOpened: false });
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { news } = this.props;

    return (
      <React.Fragment>
        {this.gallery(this.state.gallery, this.state.index)}
        {this.singleImageViewer(this.state.singleImage, this.state.singleImageIndex)}
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
              <div id="wordpressContent">
                <div dangerouslySetInnerHTML={{ __html: news.content }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
              <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
                <ShareButtons news={news} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewsContent;
