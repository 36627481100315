import React, { Component } from "react";
import moment from "moment";

import withCollections from "higherOrderComponents/withCollections";
import date from "utils/date";

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: null,
      event: props.event,
    };

    this.secondInterval = null;
    this.tick = this.tick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (prevState.event.id !== nextProps.event.id) {
      newState.event = nextProps.event;
    }

    return newState;
  }

  componentDidMount() {
    this.secondInterval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.secondInterval);
  }

  tick() {
    const startDate = date.getDateTimeNow(
      date.getMoment(
        this.state.event.start_date + " " + this.state.event.start_time,
        "DD/MM/YYYY HH:mm:ss"
      )
    );
    const diff = startDate - date.getDateTimeNow();
    if (!this.state.event.force_live && diff > 0) {
      const time = moment.duration(diff, "seconds");

      if (time >= 0) {
        this.setState({ time });
      }
    } else if (this.state.time) {
      this.setState({ time: null });
    }
  }

  render() {
    const { time } = this.state;

    return (
      <div id="countdown" className="row">
        <Card number={time && time.months()} text={time && "M"} />
        <Card number={time && time.days()} text={time && "D"} />
        <Card number={time && time.hours()} text={time && "H"} />
        <Card number={time && time.minutes()} text={time && "MIN"} />
        <Card number={time && time.seconds()} text={time && "SEC"} />
      </div>
    );
  }
}

const Card = ({ number, text }) => (
  <div
    className="countdownCard"
    style={{
      opacity: text ? 1 : 0,
      transition: "opacity 0.25s ease-in",
    }}
  >
    <h3>{number}</h3>
    <span>{text}</span>
  </div>
);

export default withCollections(Countdown);
