import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import date from "utils/date";

import withCollections from "higherOrderComponents/withCollections";

import Breadcrumbs from "../../Breadcrumbs";

class EventsHero extends Component {
  render() {
    const event =
      this.props.collections.fetchedCollections.events && this.props.collections.events.featured;

    const flag = `assets/flags/${event.country && event.country.value}.svg`;

    const datum = date.getDate(event.start_date);

    const currentDate = date.getDateTimeNow();
    const startDate = date.getMoment(event.start_date, "DD/MM/YYYY").format("X");

    return (
      <div
        className="eventsHeroContainer"
        style={{
          background: `url(${event.featured_photo && event.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <div className="heroContentContainer">
          <div className="container">
            <div className="eventsHeroInfoContainer">
              <div className="row">
                <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                  <div className="info">
                    <img
                      src={flag}
                      alt={event.country && event.country.label}
                      className="countryFlag"
                    />
                    <span className="countryName">
                      {event.country && event.country.label}, {event.city}
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                  <div className="info">
                    <i className="fas fa-calendar-alt" />
                    <span className="information">{datum}</span>
                  </div>
                </div>
                {event.information && (
                  <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                    <div className="info">
                      <i className="fas fa-info-circle" />
                      <span className="information">{event.information}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-8">
                <h1>{event.title}</h1>
                <p>
                  {event.intro && event.intro.length > 190
                    ? event.intro.slice(0, 187) + "..."
                    : event.intro}
                </p>
                <div className="heroButtonsContainer">
                  <Link to={"/events/" + event.id}>
                    <button className="button-white-hollow">Read More</button>
                  </Link>
                  {event.form_id && event.applications_open && currentDate <= startDate && (
                    <Link to={"/events/" + event.id + "/register"}>
                      <button className="button-primary">Join Event</button>
                    </Link>
                  )}
                  {event.type === "live" && event.twitter_tag ? (
                    <HashLink to={"/events/" + event.id + "/#LiveFeed"}>
                      <button className="button-primary">Live Feed</button>
                    </HashLink>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Breadcrumbs />
      </div>
    );
  }
}

export default withRouter(withCollections(EventsHero));
