import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import postTypes from "constants/postTypes";

import withCollections from "higherOrderComponents/withCollections";

import Breadcrumbs from "../../Breadcrumbs";

class StoriesHero extends Component {
  render() {
    const storyId =
      this.props.collections.fetchedCollections.stories &&
      Object.keys(this.props.collections.stories)[0];
    const story =
      this.props.collections.fetchedCollections.stories && this.props.collections.stories[storyId];

    return (
      <div
        className="storyHeroContainer"
        style={{
          background: `url(${story.featured_photo && story.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <div className="heroContentContainer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h1>{story.title}</h1>
                <p>{story.intro}</p>
                <div className="heroButtonsContainer">
                  <Link to={`${postTypes.STORIES}/${storyId}`}>
                    <button className="button-primary">Read More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Breadcrumbs />
      </div>
    );
  }
}

export default withRouter(withCollections(StoriesHero));
