import React from "react";

function TwitterModal(props) {
  return (
    <React.Fragment>
      <div className="twitterModalOverlay" onClick={props.closeModal} />
      <i className="fas fa-times fa-2x twitterModalCloseButton" onClick={props.closeModal}></i>
      <div className="twiterModalTwit" dangerouslySetInnerHTML={{ __html: props.twit }} />
    </React.Fragment>
  );
}

export default TwitterModal;
