import React from "react";
import Dropzone from "react-dropzone";

function Filedrop({ field, data, onDrop, errorMessage }) {
  return (
    <React.Fragment>
      <Dropzone
        accept={["image/*"]}
        onDrop={acceptedFiles => onDrop({ ...data, [field.id]: acceptedFiles[0] })}
        value={data[field.id] || ""}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="registerFormButtonUpload" name={field.id}>
            <div className="registerFormButtonUploadContainer" {...getRootProps()}>
              <input {...getInputProps()} />
              {data[field.id] ? <p>{data[field.id].name}</p> : <p>{field.label}</p>}
            </div>
          </section>
        )}
      </Dropzone>
      {errorMessage && (
        <span className="registerFormErrorMessage">This field cannot be empty!</span>
      )}
    </React.Fragment>
  );
}
export default Filedrop;
