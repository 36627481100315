import React, { Component } from "react";

import { Helmet } from "react-helmet";
import NotFound from "../NotFound";
import TeamContent from "../../components/TeamContent";
import TeamHero from "../../components/Heros/TeamHero";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.team_id !== prevState.teamId) {
      const teamId = nextProps.match.params.team_id;
      return { teamId };
    }
    return null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const team =
      this.props.collections.fetchedCollections.teams &&
      this.props.collections.teams[this.state.teamId];

    if (!team) {
      return <NotFound />;
    }

    return (
      <div className="teamContainer">
        <Helmet>
          <title> Seascape Edition | Team - {team.team_name}</title>
        </Helmet>

        <section>
          <TeamHero />
        </section>
        <section id="navbarTrigger" className="teamContentSection">
          <TeamContent team={team} />
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(Teams));
