import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";

import WideCardEvents from "../Cards/WideCardEvents";

class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upcoming: true,
    };
  }

  renderEvents() {
    if (
      this.state.upcoming &&
      this.props.collections.fetchedCollections.events &&
      this.props.collections.events.live.length === 0 &&
      Object.values(this.props.collections.events.upcoming)[0] &&
      Object.values(this.props.collections.events.upcoming)[0].ID ===
        this.props.collections.events.featured.ID
    ) {
      return (
        this.props.collections.fetchedCollections.events &&
        Object.keys(this.props.collections.events.upcoming).map((eventId, index) => {
          if (index !== 0) {
            const event = this.props.collections.events.upcoming[eventId];
            event.id = eventId;
            return <WideCardEvents loading={true} event={event} key={index} />;
          }
          return null;
        })
      );
    } else if (
      this.props.collections.events.upcoming &&
      !Object.keys(this.props.collections.events.upcoming).length &&
      this.state.upcoming
    ) {
      return (
        <div className="row">
          <div className="col-12">
            <div className="noEventsContainer">
              <h4>Currently there is no upcoming event. You can check our past events.</h4>
            </div>
          </div>
        </div>
      );
    } else if (this.state.upcoming) {
      return (
        this.props.collections.fetchedCollections.events &&
        Object.keys(this.props.collections.events.upcoming).map((eventId, index) => {
          const event = this.props.collections.events.upcoming[eventId];
          event.id = eventId;
          return <WideCardEvents loading={true} event={event} key={index} />;
        })
      );
    } else {
      return (
        this.props.collections.fetchedCollections.events &&
        Object.keys(this.props.collections.events.past).map((eventId, index) => {
          const event = this.props.collections.events.past[eventId];
          event.id = eventId;
          return <WideCardEvents loading={true} event={event} key={index} />;
        })
      );
    }
  }

  render() {
    return (
      <div className="eventsListContainer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="titleAndButtonsContainer">
                <h2>Discover all events</h2>
                <div className="eventsListButtonsContainer">
                  <button
                    className={`timeButtonLeft ${this.state.upcoming ? "active-button" : ""}`}
                    onClick={() => this.setState({ upcoming: true })}
                  >
                    Upcoming events
                  </button>
                  <button
                    className={`timeButtonRight ${this.state.upcoming ? "" : "active-button"}`}
                    onClick={() => this.setState({ upcoming: false })}
                  >
                    Past events
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">{this.renderEvents()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCollections(EventsList);
