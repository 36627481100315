import 'babel-polyfill';

import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";

import About from "views/About";
import Boats from './views/Boats';
import Community from "views/Community";
import Events from "views/Events";
import Footer from "components/Footer";
import FullScreenLoader from "components/FullScreenLoader";
import Home from "views/Home";
import KnowledgeBase from "views/KnowledgeBase";
import LegalNotice from "./views/LegalNotice";
import Navbar from "components/Navbar";
import News from "views/News";
import NotFound from "./views/NotFound";
import OwnersClubs from './views/OwnersClubs';
import Projects from "./views/Projects";
import RegisterForm from "views/RegisterForm";
import SingleBoat from './views/SingleBoat';
import SingleEvent from "views/SingleEvent";
import SingleNews from "views/SingleNews";
import SingleOwnersClub from "./views/SingleOwnersClubs";
import SingleStory from "views/SingleStory";
import Stories from "views/Stories";
import Teams from "./views/Teams";
import Vlog from "views/Vlog";
import WelcomeAboard from "./views/WelcomeAboard";
import api from "./api/posts";
import { createBrowserHistory } from "history";
import routes from "constants/routes";

// import { getCookie } from "utils/cookie";
// import CookieConsent from "react-cookie-consent";


const hist = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    api.pullContent().then(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    // if (getCookie("googleAnalytics")) {
    this.addAnalytics();
    // }
  }

  addAnalytics = () => {
    const gTagManager = document.createElement("script");
    gTagManager.async = true;
    gTagManager.src = "https://www.googletagmanager.com/gtag/js?id=UA-145626509-1";
    const gAnalytics = document.createElement("script");
    gAnalytics.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-145626509-1', {'anonymize_ip': true});`;

    document.body.append(gTagManager);
    document.body.append(gAnalytics);
  };

  render() {
    return (
      <Router history={hist}>
        <FullScreenLoader loading={!this.state.loading} />
        <Navbar />
        <Switch>
          <Route exact path={routes.HOME} component={() => <Home loading={this.state.loading} />} />
          <Route exact path={routes.NEWS} component={News} />
          <Route exact path={routes.NEWS + "/:id"} component={SingleNews} />
          <Route exact path={routes.EVENTS} component={Events} />
          <Route exact path={routes.EVENTS + "/:id/register"} component={RegisterForm} />
          <Route exact path={routes.EVENTS + "/:id/team/:team_id"} component={Teams} />
          <Route exact path={routes.EVENTS + "/:id"} component={SingleEvent} />
          <Route exact path={routes.STORIES + "/:id"} component={SingleStory} />
          <Route exact path={routes.STORIES} component={Stories} />
          <Route exact path={routes.ABOUT} component={About} />
          <Route exact path={routes.COMMUNITY} component={Community} />
          <Route exact path={routes.LEGAL} component={LegalNotice} />
          <Route exact path={routes.WELCOME_ABOARD} component={WelcomeAboard} />
          <Route exact path={routes.PROJECTS} component={Projects} />
          <Route exact path={routes.BOATS} component={Boats} />
          <Route exact path={routes.BOATS+ "/:id"} component={SingleBoat} />
          <Route exact path={routes.KNOWLEDGE_BASE} component={KnowledgeBase} />
          <Route exact path={routes.OWNERS_CLUBS} component={OwnersClubs} />
          <Route exact path={routes.OWNERS_CLUBS + "/:id"} component={SingleOwnersClub} />
          <Route exact path={routes.VLOG} component={Vlog} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
        {/* <CookieConsent
          buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
          buttonText="I AGREE"
          cookieName="googleAnalytics"
          expires={365}
          location="bottom"
          onAccept={this.addAnalytics}
          style={{ background: "#2B373B", zIndex: 2147483001 }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent> */}
      </Router>
    );
  }
}

export default App;
