import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import postReducer from "./postReducer";

const rootReducer = combineReducers({
  page: pageReducer,
  posts: postReducer,
});

export default rootReducer;
