import React, { Component } from "react";

class BoatFeatures extends Component {

  componentDidMount() {
    document.querySelectorAll(".aboutContainer iframe").forEach(iframe => {
      const container = document.createElement("div");
      container.className = "youtubeIframeContainer";
      iframe.parentNode.insertBefore(container, iframe);
	    container.appendChild(iframe);
    });
  }
  
  render() {
    const { boat } = this.props;
    
    if (!(boat.features || boat.features_gallery.length)) {
      return (
        <div className="container">
          <div className="comunityContentHeaderContainer mt-8">
            <p className="text-center">No features posted yet</p>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        {boat.features ?
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <p className="boatFeatures">
                {boat.features}
              </p>
            </div>
          </div>
          : <div className="comunityContentHeaderContainer mt-8">
              <p className="text-center">No features posted yet</p>
            </div>
        }

        {boat.features_gallery.length ?
          <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              {boat.features_gallery.length && boat.features_gallery.map((_, index) => (
                  <li key={index} data-target="#carouselIndicators" data-slide-to={index} className={index === 0 ? "active": ""}></li>
                ))
              }
            </ol>
            <div className="carousel-inner">
              {boat.features_gallery.length && boat.features_gallery.map((photo, index) => (
                  <div key={index} className={`carousel-item ${index === 0 ? "active": ""}`}>
                    <div 
                      className="d-block mx-auto carouselImage" 
                      style={{
                        backgroundImage: `url(${photo["1536x1536"]})`,
                      }}
                    />
                  </div>
                  ))
              }
            </div>
            <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
          : null
        }
      </div>
    );
  }
}

export default BoatFeatures;