import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

const defaultTeamPhoto = "/assets/defaultTeamPhoto.svg";

class EventTeamCard extends Component {
  render() {
    const { team, index } = this.props;
    const eventId = this.props.match.params.id;
    let boatType;
    
    try {
      boatType = JSON.parse(team.boat_type);
    } catch {
      boatType = team.boat_type;
    }

    return (
      <Link to={"/events/" + eventId + "/team/" + team.id}>
        <div className="eventTeamCardContainer">
          <div
            className="teamImageContainer"
            style={{ background: `url(${(team && team.team_photo) || defaultTeamPhoto})` }}
          >
            <div className="teamImageOverlay" />
          </div>
          <div className="teamNumber">{index + 1}</div>
          <div className="teamName">{team.team_name}</div>
          <div className="boatName">{boatType}</div>
        </div>
      </Link>
    );
  }
}

export default withRouter(EventTeamCard);
