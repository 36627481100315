import React, { Component } from "react";

import { withRouter } from "react-router-dom";

class YoutubeCard extends Component {
  render() {
    return (
      <a href={`https://youtu.be/${this.props.video.id}`} target="_blank" rel="noopener noreferrer">
        <div id="storyCard">
          <div
            className="storyContent"
            style={{
              backgroundImage: `url(${this.props.video.photo && this.props.video.photo.thumbnail})`
            }}
          />
            <div className="storyCardIcon">
                <i className="fas fa-play fa-3x" />
            </div>

        </div>
      </a>
    );
  }
}

export default withRouter(YoutubeCard);
