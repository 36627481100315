import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import date from "utils/date";

import CarouselCard from "components/Cards/CarouselCard";
import Countdown from "components/Countdown";

const postMapper = {
  event: "events",
  post: "news",
  story: "stories",
};

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indicatorActive: true,
      posts: null,
      postIndex: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (nextProps.posts && !prevState.posts) {
      newState.posts = nextProps.posts;
      newState.postIndex = 0;
    }

    return newState;
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    if (window.innerWidth > 576) {
      this.interval = setInterval(() => {
        this.setState({ postIndex: (this.state.postIndex + 1) % 3 });
      }, 7500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    this.clearInterval(this.interval);
  }

  onResize = () => {
    if (window.innerWidth <= 576) {
      this.clearInterval(this.interval);
      this.setState({ postIndex: 0 });
    }
  };

  clearInterval = () => {
    window.clearInterval(this.interval);
  };

  nextCard = () => {
    this.setState(
      { postIndex: (this.state.postIndex + 1) % 3, indicatorActive: false },
      this.clearInterval
    );
  };

  gotoCard = index => {
    if (this.state.postIndex === index) {
      const currentPost = this.state.posts && this.state.posts[this.state.postIndex];
      this.props.history.push(`/${postMapper[currentPost.post_type]}/${currentPost.id}`);
    } else {
      this.setState({ postIndex: index, indicatorActive: false }, this.clearInterval);
    }
  };

  prevCard = () => {
    this.setState(
      { postIndex: (this.state.postIndex + 2) % 3, indicatorActive: false },
      this.clearInterval
    );
  };

  render() {
    const currentPost = this.state.posts && this.state.posts[this.state.postIndex];
    const datum =
      (currentPost && currentPost.start_date && date.getDate(currentPost.start_date)) || null;
    const flag =
      (currentPost &&
        currentPost.country &&
        `/assets/flags/${currentPost.country && currentPost.country.value}.svg`) ||
      null;

    const currentDate = date.getDateTimeNow();
    const startDate =
      currentPost &&
      currentPost.post_type === "event" &&
      date.getMoment(currentPost.start_date, "DD/MM/YYYY").format("X");

    return (
      <React.Fragment>
        {this.state.posts &&
          this.state.posts.map((post, index) => (
            <div
              className={`carouselBackgroundImage ${
                this.state.postIndex === index ? "carouselBackgroundImageActive" : ""
              }`}
              key={index}
              style={{
                background: `url(${post.featured_photo.large})`,
              }}
            />
          ))}
        <div className="carouselContainer">
          <div className="heroOverlay" />
          <div className="heroContentContainer">
            <div className="container">
              {currentPost && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12">
                      <Countdown event={currentPost} />
                    </div>
                  </div>
                  <div className="eventsHeroInfoContainer">
                    <div className="row">
                      {(currentPost.country && (
                        <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                          <div className="info">
                            <img
                              src={flag}
                              alt={currentPost.country && currentPost.country.label}
                              className="countryFlag"
                            />
                            <span className="countryName">
                              {currentPost.country && currentPost.country.label}, {currentPost.city}
                            </span>
                          </div>
                        </div>
                      )) ||
                        null}
                      {(datum && (
                        <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                          <div className="info">
                            <i className="fas fa-calendar-alt" />
                            <span className="information">{datum}</span>
                          </div>
                        </div>
                      )) ||
                        null}
                      {currentPost.information && (
                        <div className="col-xl-3 col-mdlg-4 col-md-6 col-sm-12">
                          <div className="info">
                            <i className="fas fa-info-circle" />
                            <span className="information">{currentPost.information}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="row">
                {currentPost && (
                  <div className="col-md-12 col-mdlg-7 col-lg-7">
                    <h1>{currentPost.title}</h1>
                    <p className="carouselIntro">{currentPost.intro}</p>
                    <div className="row">
                      <div className="col-6 col-lg-3">
                        <Link to={`/${postMapper[currentPost.post_type]}/${currentPost.id}`}>
                          <button className="button-white-hollow">Read More</button>
                        </Link>
                      </div>
                      {currentPost.form_id &&
                        currentPost.applications_open &&
                        currentDate <= startDate && (
                          <div className="col-6 col-lg-3">
                            <Link to={"/events/" + currentPost.id + "/register"}>
                              <button className="button-primary">Join Event</button>
                            </Link>
                          </div>
                        )}
                      {currentPost.type === "live" && currentPost.twitter_tag ? (
                        <div className="col-6 col-lg-3">
                          <HashLink to={"/events/" + currentPost.id + "/#LiveFeed"}>
                            <button className="button-primary">Live Feed</button>
                          </HashLink>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="carouselCardController">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="carouselIndexContainer">
                    <span className="carouselIndex">0{this.state.postIndex + 1}</span>
                    <div className="carouselIndexLine" />
                    <span className="carouselIndexLenght">03</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carouselCardSection">
            <div className="row">
              {this.state.posts &&
                this.state.posts.map((post, index) => (
                  <CarouselCard
                    active={this.state.postIndex === index}
                    backgroundImage={post.thumbnail_photo && post.thumbnail_photo.large ? post.thumbnail_photo.large : post.featured_photo.large}
                    index={index}
                    key={index}
                    onClick={this.gotoCard}
                    title={post && post.title}
                    transform={this.state.indicatorActive}
                  />
                ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Carousel);
