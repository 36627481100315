import React, { Component } from "react";

const defaultTeamPhoto = "/assets/defaultTeamPhoto.svg";

class TeamContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openTeamPhoto: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  gallery() {
    const { team } = this.props;

    if (this.state.openTeamPhoto) {
      document.addEventListener("keydown", this.handleKeyDown);

      return (
        <div className="galleryContainer">
          <div
            className="galleryOverlay"
            onClick={() => {
              this.setState({ openTeamPhoto: false });
              document.removeEventListener("keydown", this.handleKeyDown);
            }}
          />
          <div className="imageContainer">
            <img src={team.team_photo || defaultTeamPhoto} alt={team.team_name} />
          </div>
          <div className="closeButton">
            <i
              className="fas fa-times fa-2x"
              onClick={() => {
                this.setState({ openTeamPhoto: false });
                document.removeEventListener("keydown", this.handleKeyDown);
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  handleKeyDown(event) {
    event.preventDefault();
    // Esc key
    if (this.state.openTeamPhoto && event.keyCode === 27) {
      this.setState({ openTeamPhoto: false });
      document.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  renderCrew() {
    const { team } = this.props;
    return (
      team.crew_members &&
      team.crew_members.map((crew, i) => {
        return (
          <div key={i} className="cardText">
            {crew}
          </div>
        );
      })
    );
  }

  getBoatType(boat_type) {
    try {
      return JSON.parse(boat_type);
    } catch {
      return boat_type;
    }
  }

  render() {
    const { team } = this.props;
    return (
      <div className="teamContentContainer">
        {this.gallery()}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{team.team_name}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="boatNameContainer">
                <div className="cardTitle">BOAT NAME</div>
                <div className="cardText">{team.team_name}</div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="aboutBoatContainer">
                    <div className="cardTitle">SKIPPER</div>
                    <div className="cardText">{team.skipper_name}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="aboutBoatContainer">
                    <div className="cardTitle">SAIL NUMBER</div>
                    <div className="cardText">{team.sail_number}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomSectionContainer">
            <div className="row">
              <div className="col-mdlg-5 col-md-12">
                <div
                  className="teamImageContainer"
                  style={{
                    background: `url(${team.team_photo || defaultTeamPhoto})`,
                  }}
                  onClick={() => {
                    this.setState({ openTeamPhoto: true });
                  }}
                />
              </div>
              <div className="col-mdlg-7 col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="crewContainer">
                      <div className="cardTitle">CREW</div>
                      {this.renderCrew()}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="classContainer">
                      <div className="cardTitle">CLASS</div>
                      <div className="cardText">{this.getBoatType(team.boat_type)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {team.team_presentation_video ? (
            <div className="row">
              <div className="col-12">
                <iframe
                  src={`https://www.youtube.com/embed/${team.team_presentation_video}`}
                  frameBorder="0"
                  allowFullScreen
                  title="youtube presentation video"
                  className="youtubeTeamVideo"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TeamContent;
