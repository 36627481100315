import React, { Component } from "react";

import Breadcrumbs from "../../Breadcrumbs";

class RegisterFormHero extends Component {
  render() {
    const { event } = this.props;

    return (
      <div
        className="registerFormHero"
        style={{
          background: `url(${event.featured_photo && event.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <Breadcrumbs />
      </div>
    );
  }
}

export default RegisterFormHero;
