import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import { Helmet } from "react-helmet";
import api from "../../api/posts";
import routes from "../../constants/routes";
import withCollections from "higherOrderComponents/withCollections";

class Boats extends Component {
  constructor(props) {
    super(props);
    api.pullBoats();
    api.pullBoatModels();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="community">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Seascape Edition | Boats</title>
          <meta
            name="description"
            content="The machines that bring high-tech and performance from the cutting edge of racing sailing into the amateur sailors' hands."
          />
        </Helmet>
        <section>
          <div
            className="communityHero"
            style={
              this.props.collections.boats.featured_photo && {
                background: `url(${this.props.collections.boats.featured_photo.large})`,
              }
            }
          >
            <div className="communitysHeroOverlay" />
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="communityHeroContainer">
                    <h1 className="communityTitle">
                      {this.props.collections.boats.title}
                    </h1>
                    <p className="communitySubtitle">
                      {this.props.collections.boats.intro}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="navbarTrigger">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="comunityContentHeaderContainer">
                  <h2>{this.props.collections.boats.first_subtitle}</h2>
                </div>
              </div>
              {this.props.collections.fetchedCollections.boatModels &&
                Object.keys(this.props.collections.boatModels)
                  .reverse()
                  .map((boatId) => {
                    const boat = this.props.collections.boatModels[boatId];
                    return (
                      <div
                        key={boatId}
                        className="col-sm-12 col-md-3 col-lg-3 mb-4"
                      >
                        <Link to={`${routes.BOATS}/${boatId}`}>
                          <div id="storyCard">
                            <div
                              className="storyContent "
                              style={{
                                background: `url(${
                                  boat.card_photo &&
                                  boat.card_photo.medium_large
                                })`,
                              }}
                            >
                              <div className="storyCardTitle">
                                <h3>{boat.title}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              <div className="col-12">
                <div className="comunityContentHeaderContainer communityContentCards">
                  <h2>
                    Discover the whole First range on{" "}
                    <a
                      href="https://www.beneteau.com/"
                      className="underlined-link"
                    >
                      beneteau.com
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(Boats));
