import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class OwnersClubHero extends Component {
  componentDidMount() {
    if (!this.props.location.hash) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    if (!this.props.collections.fetchedCollections.ownersClubs) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          className="communityHero"
          style={
            this.props.collections.ownersClubs.featured_photo && {
              background: `url(${this.props.collections.ownersClubs.featured_photo.large})`,
            }
          }
        >
          <div className="communitysHeroOverlay" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="nationalClassesHeroContainer">
                  <h1 className="communityTitle">{this.props.collections.ownersClubs.title}</h1>
                  <p className="communitySubtitle">{this.props.collections.ownersClubs.intro}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withCollections(OwnersClubHero));
