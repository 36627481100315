import React, { Component } from "react";

import CommunityCards from "../Cards/CommunityCards";
import CommunityImpressions from "../CommunityImpressions";
import KnowledgeBaseNews from "../KnowledgeBaseNews";
import withCollections from "higherOrderComponents/withCollections";

class KnowledgeBaseContent extends Component {
  render() {
    const {knowledgeBase} = this.props;
    const {community} = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <KnowledgeBaseNews knowledgeBase={knowledgeBase} />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="comunityContentHeaderContainer communityContentCards">
                <h2>{community.subtitle}</h2>
                <p>{community.sub_intro}</p>
              </div>
            </div>
            <CommunityCards community={community} />
          </div>
        </div>
        <CommunityImpressions community={community} />
      </React.Fragment>
    );
  }
}

export default withCollections(KnowledgeBaseContent);
