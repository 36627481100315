import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import LiveTopbar from "components/LiveTopbar";
import routes from "constants/routes";
import withCollections from "higherOrderComponents/withCollections";

const logo = "/assets/logo.svg";
const logoIcon = "/assets/logo-icon.svg";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpenAbsolute: false,
      menuOpenFixed: false,
      navbarFixed: false,
      nextPage: false,
      newsId: null,
    };

    this.handleAbsoluteMenuState = this.handleAbsoluteMenuState.bind(this);
    this.handleFixedMenuState = this.handleFixedMenuState.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.location.pathname.includes("/news") &&
      nextProps.location.pathname.split("/").length === 3
    ) {
      return {
        nextPage: true,
        newsId: nextProps.location.pathname.split("/").pop(),
      };
    } else if (nextProps.location.pathname === "/news") {
      return { nextPage: true };
    } else if (
      (!nextProps.location.pathname === "/news" && !prevState.navbarFixed) ||
      (prevState.nextPage && !prevState.navbarFixed)
    ) {
      return { nextPage: false };
    }
    return {};
  }

  handleScroll() {
    const triggerFixedNavbarIf = (condition) => {
      if (condition && !this.state.navbarFixed) {
        this.setState({
          navbarFixed: true,
          nextPage: true,
          menuOpenAbsolute: false,
        });
      } else if (!condition && this.state.navbarFixed) {
        this.setState({
          navbarFixed: false,
          nextPage: false,
          menuOpenFixed: false,
        });
      }
    };
    try {
      const scrolledPastSection =
        window.scrollY >= document.getElementById("navbarTrigger").offsetTop;

      triggerFixedNavbarIf(scrolledPastSection);
    } catch {
      return;
    }
  }

  handleAbsoluteMenuState() {
    this.setState({ menuOpenAbsolute: !this.state.menuOpenAbsolute });
  }

  handleFixedMenuState() {
    this.setState({ menuOpenFixed: !this.state.menuOpenFixed });
  }

  render() {
    const location = this.props.location.pathname;
    const news =
      this.props.collections.news &&
      this.state.newsId &&
      this.props.collections.news[this.state.newsId];

    const menuLinks = [
      <Link
        to={routes.NEWS} // eslint-disable-next-line
        className={`menuLink${
          (news &&
            !news.community &&
            this.props.location.pathname.includes(routes.NEWS)) ||
          this.props.location.pathname === routes.NEWS
            ? "Active"
            : ""
        }`}
      >
        News
      </Link>,
      <Link
        to={routes.STORIES}
        className={`menuLink${
          this.props.location.pathname.includes(routes.STORIES) ? "Active" : ""
        }`}
      >
        Stories
      </Link>,
      <Link
        to={routes.EVENTS}
        className={`menuLink${
          this.props.location.pathname.includes(routes.EVENTS) ? "Active" : ""
        }`}
      >
        Events
      </Link>,
      <Link
        to={routes.COMMUNITY}
        className={`menuLink${
          (news && news.community) ||
          [
            routes.COMMUNITY,
            routes.NATIONAL_CLASSES,
            routes.KNOWLEDGE_BASE,
            routes.VLOG,
          ].includes(this.props.location.pathname)
            ? "Active"
            : ""
        }`}
      >
        Community
      </Link>,
      <Link
        to={routes.BOATS}
        className={`menuLink${
          this.props.location.pathname.includes(routes.BOATS) ? "Active" : ""
        }`}
      >
        Boats
      </Link>,
      <Link
        to={routes.ABOUT}
        className={`menuLink${
          this.props.location.pathname.includes(routes.ABOUT) ? "Active" : ""
        }`}
      >
        About
      </Link>,
    ];

    const liveEventKey =
      (this.props.collections.fetchedCollections.events &&
        Object.keys(this.props.collections.events.live)[0]) ||
      null;

    let liveEvent =
      (this.props.collections.fetchedCollections.events &&
        Object.values(this.props.collections.events.live)[0]) ||
      null;

    liveEvent && liveEventKey && (liveEvent.id = liveEventKey);

    return (
      <React.Fragment>
        {liveEvent && <LiveTopbar liveEvent={liveEvent} />}
        {/* ABSOLUTE NAVBAR */}
        <div
          id="navbarAbsolute"
          className={`row no-gutters ${
            this.state.menuOpenAbsolute ? "navbarMenuBackground" : ""
          }`}
          style={{
            display: location === "/news" && "none",
            top: liveEvent ? "100px" : 0,
          }}
        >
          <div className="offset-xxl-2 col-xxl-2 col-xl-2 col-md-4 col-10">
            <Link to={routes.HOME} className="d-flex align-items-center">
              <img
                alt="Seascape"
                className="navbarLogo navbarLogoIcon"
                src={logoIcon}
              />
              <img alt="Seascape" className="navbarLogo" src={logo} />
            </Link>
          </div>
          <div className="col-xxl-6 col-xl-10 col-md-8 col-2 navbarRight">
            <div className="navbarLinks text-right">
              {menuLinks.map((link, index) => (
                <React.Fragment key={index}>{link}</React.Fragment>
              ))}
            </div>
            <div
              onClick={this.handleAbsoluteMenuState}
              className={`navbarIcon ${
                this.state.menuOpenAbsolute ? "navbarIconActive" : ""
              }`}
            >
              <i className="fas fa-bars" />
            </div>
          </div>
        </div>
        {/* ABSOLUTE NAVBAR MENU */}
        <div
          id="navbarMenuAbsolute"
          className={`${this.state.menuOpenAbsolute ? "navbarMenuOpen" : ""}`}
          style={{ top: liveEvent ? "170px" : "70px" }}
        >
          <div className="navbarMenuContainer">
            {menuLinks.map((link, index) => (
              <div key={index} onClick={this.handleAbsoluteMenuState}>
                {link}
              </div>
            ))}
          </div>
        </div>
        {/* FIXED NAVBAR */}
        <div
          className={`row no-gutters navbarFixed ${
            this.state.nextPage ? "navbarFixedVisible" : ""
          }`}
          id="navbarFixed"
        >
          <div className="offset-xxl-2 col-xxl-2 col-xl-2 col-md-4 col-10">
            <Link to={routes.HOME} className="d-flex align-items-center">
              <img
                alt="Seascape"
                className="navbarLogo navbarLogoIcon"
                src={logoIcon}
              />
              <img alt="Seascape" className="navbarLogo" src={logo} />
            </Link>
          </div>
          <div className="col-xxl-6 col-xl-10 col-md-8 col-2 navbarRight">
            <div className="navbarLinks text-right">
              {menuLinks.map((link, index) => (
                <React.Fragment key={index}>{link}</React.Fragment>
              ))}
            </div>
            <div onClick={this.handleFixedMenuState} className="navbarIcon">
              <i className="fas fa-bars" />
            </div>
          </div>
        </div>
        {/* FIXED NAVBAR MENU */}
        <div
          className={`navbarMenuFixed ${
            this.state.menuOpenFixed ? "navbarMenuOpen" : ""
          }`}
        >
          <div className="navbarMenuContainer">
            {menuLinks.map((link, index) => (
              <div key={index} onClick={this.handleFixedMenuState}>
                {link}
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withCollections(Navbar));
