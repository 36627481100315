import React, { Component } from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import Select from "react-select";
import WideCardNews from "../Cards/WideCardNews";
import _ from "lodash";
import withCollections from "higherOrderComponents/withCollections";

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedNews: 15,
      searchKeyword: "",
      tags: [],
    };
  }

  setSearchKeyword(searchKeyword) {
    this.setState({ searchKeyword });
  }

  filterNewsByInput(news) {
    const keyword = this.state.searchKeyword.toLowerCase();
    return (
      _.get(news, "content", "").toLowerCase().includes(keyword) ||
      _.get(news, "intro", "").toLowerCase().includes(keyword) ||
      _.get(news, "title", "").toLowerCase().includes(keyword) ||
      _.get(news, "author", "").toLowerCase().includes(keyword)
    );
  }

  filterNewsByTag(news) {
    return this.state.tags.length
      ? this.state.tags.some((tag) => _.get(news, "tag", []).includes(tag))
      : true;
  }

  showNews() {
    if (this.props.collections.fetchedCollections.news) {
      if (this.state.searchKeyword || this.state.tags.length) {
        const filteredNews = Object.entries(this.props.collections.news)
          .filter(([id, news]) => this.filterNewsByTag(news))
          .filter(([id, news]) => this.filterNewsByInput(news))
          .map(([id, news], index) => {
            if (!news.community) {
              news.id = id;
              return <WideCardNews news={news} key={index} />;
            }
            return null;
          });
        if (!filteredNews.length) {
          return (
            <div className="newsListEmptyMessage">
              {this.state.tags.length ? (
                <span>
                  Filtering by "
                  {this.state.tags
                    .map(
                      (tag) =>
                        Object.values(this.props.collections.tags).find(
                          (t) => t.value === tag
                        ).label
                    )
                    .join(", ")}
                  " didn't provide any results
                </span>
              ) : (
                <span>
                  Keyword "{this.state.searchKeyword}" didn't provide any
                  results
                </span>
              )}
            </div>
          );
        }
        return filteredNews;
      }
      return Object.keys(this.props.collections.news).map((newsId, index) => {
        if (index < this.state.loadedNews) {
          const news = this.props.collections.news[newsId];
          news.id = newsId;
          if (!news.community) {
            return <WideCardNews news={news} key={index} />;
          }
        }
        return null;
      });
    }
    return null;
  }

  selectTags(selectedTags) {
    const tags = selectedTags.map((tag) => tag.value);
    this.setState({ tags: tags.length ? tags : [] });
  }

  render() {
    //eslint-disable-next-line
    const options =
      (this.props.collections.fetchedCollections.tags &&
        Object.values(this.props.collections.tags)) ||
      [];

    return (
      <div className="newsListContainer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="newsListBreadcrumbsContainer">
                <Breadcrumbs color="#1b232b" />
              </div>
              <div className="titleAndButtonsContainer">
                <h2>Discover all news</h2>
                <div className="newsListSearch">
                  <input
                    onInput={(e) => this.setSearchKeyword(e.target.value)}
                    placeholder="Search news"
                    type="search"
                  />
                  <i className="fa fa-search" />
                </div>
              </div>
            </div>
          </div>

          <Select
            isMulti
            closeMenuOnSelect={false}
            options={options}
            onChange={(e) => this.selectTags(e)}
            className="mt-4"
            placeholder="Filter by tags"
          />

          <div className="row">
            <div className="col-12">{this.showNews()}</div>
          </div>

          {!(this.state.searchKeyword || this.state.tags.length) &&
          Object.keys(this.props.collections.news).length >
            this.state.loadedNews ? (
            <div className="row">
              <div className="col-12">
                <div className="loadMoreButtonContainer">
                  <button
                    className="load-more-button"
                    onClick={() => {
                      this.setState({ loadedNews: this.state.loadedNews + 15 });
                    }}
                  >
                    Load more news
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withCollections(NewsList);
