import React, { Component } from "react";

import { Helmet } from "react-helmet";
import HorizontalScroll from "components/Scrollers/Horizontal";
import NotFound from "../NotFound";
import SingleStoryHero from "components/Heros/SingleStoryHero";
import StoryCard from "components/Cards/StoryCard";
import StoryContent from "components/StoryContent";
import date from "utils/date";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class SingleStory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storyId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.storyId) {
      window.scrollTo(0, 0);
      const storyId = nextProps.match.params.id;
      return { storyId };
    }

    return null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const currentStory =
      this.props.collections.fetchedCollections.stories &&
      this.props.collections.stories[this.state.storyId];

    if (!currentStory) {
      return <NotFound />;
    }

    const stories =
      this.props.collections.fetchedCollections.stories &&
      Object.keys(this.props.collections.stories).map((storyId) => ({
        ...this.props.collections.stories[storyId],
        id: storyId,
      }));

    const publishDate = date.getPublishDate(currentStory.post_date);

    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);

    return (
      <div id="singleStory">
        <Helmet>
          <title> Seascape Edition | Story - {currentStory.title}</title>
          <meta name="description" content={currentStory.intro} />
        </Helmet>

        <SingleStoryHero story={currentStory} />

        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
              <div className="publishDate">
                <span>
                  Published {editedPublishDate}{" "}
                  {currentStory.author
                    ? `written by ${currentStory.author}`
                    : null}
                </span>
                {currentStory.read_time && (
                  <React.Fragment>
                    <br />
                    <span>
                      Estimated reading time: {currentStory.read_time} minute
                      {currentStory.read_time > 1 ? "s" : ""}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <section id="navbarTrigger">
          <StoryContent currentStory={currentStory} />
        </section>
        <div className="container">
          {currentStory.related_stories && (
            <React.Fragment>
              <h2>Related Stories</h2>
              <StoryScrollSection
                list={stories}
                filterBy={(story) =>
                  currentStory.related_stories.includes(story.id)
                }
                index={0}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const StoryScrollSection = ({ list, filterBy, index }) => (
  <HorizontalScroll index={index}>
    {list &&
      list.filter(filterBy).map((story) => (
        <div className="storyScrollSectionCard" key={story.id}>
          <StoryCard story={story} />
        </div>
      ))}
  </HorizontalScroll>
);

export default withRouter(withCollections(SingleStory));
