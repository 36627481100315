import React, { Component } from "react";

class EventAbout extends Component {

  componentDidMount() {
    document.querySelectorAll(".aboutContainer iframe").forEach(iframe => {
      const container = document.createElement("div");
      container.className = "youtubeIframeContainer";
      iframe.parentNode.insertBefore(container, iframe);
	    container.appendChild(iframe);
    });
  }
  
  render() {
    const { event } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <div className="aboutContainer">
              <h2>About the event</h2>
              <div className="aboutContent" dangerouslySetInnerHTML={{ __html: event.content }} />
            </div>
          </div>
          {event && event.files && (
            <div className="col-12">
              <div className="fileButtonsContainer">
                {event.files.map((file, index) => {
                  return (
                    <a key={index} target="_blank" rel="noopener noreferrer" href={file.file.url}>
                      <button className="button-white-hollow" key={index}>
                        {file.file.title}
                      </button>
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EventAbout;
