import React, { Component } from "react";

import EventsHero from "../../components/Heros/EventsHero";
import EventsList from "../../components/EventsList";
import { Helmet } from "react-helmet";

class Events extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="eventsContainer">
        <Helmet>
          <title> Seascape Edition | Events</title>
          <meta
            name="description"
            content="Expand your comfort zone, grow as a sailor, meet likeminded people and enjoy the Seascape quality of life."
          />
        </Helmet>
        <section>
          <EventsHero />
        </section>
        <section id="navbarTrigger">
          <EventsList />
        </section>
      </div>
    );
  }
}

export default Events;
