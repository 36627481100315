import React, { Component } from "react";

import Breadcrumbs from "../../Breadcrumbs";

const image = "/assets/underwater.jpg";

class TeamHero extends Component {
  render() {
    return (
      <div
        className="teamHeroContainer"
        // TEAM PHOTO
        // style={{
        //   background: `url(${team.featured_photo && news.featured_photo.large})`,
        // }}

        style={{
          background: `url(${image})`,
        }}
      >
        <div className="heroOverlay" />
        <Breadcrumbs />
      </div>
    );
  }
}

export default TeamHero;
