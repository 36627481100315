import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import routes from "constants/routes";
import date from "utils/date";

class EventCard extends Component {
  render() {
    const flag = `assets/flags/${this.props.event.country.value}.svg`;

    const day = date.getDay(this.props.event.start_date);
    const month = date.getMonth(this.props.event.start_date);

    return (
      <Link to={`${routes.EVENTS}/${this.props.event.id}`}>
        <div id="eventCard">
          <div className="eventCardTop">
            <div className="eventCardImageContainer">
              <div
                className="eventCardImage"
                style={{
                  background: `linear-gradient(to bottom, transparent, black), url(${
                    this.props.event.thumbnail_photo && this.props.event.thumbnail_photo.thumbnail ? this.props.event.thumbnail_photo.thumbnail : this.props.event.featured_photo.thumbnail
                  })`,
                }}
              />
            </div>
            <div className="date">
              <span className="dateNumber">{day}</span>
              <span>{month}</span>
            </div>
            <div className="eventCardTitle">
              <h4>EVENT</h4>
              <h3>{this.props.event.title}</h3>
            </div>
          </div>
          <div className="eventCardBottom">
            <div className="country">
              <img src={flag} alt="country name" className="countryFlag" />
              <span className="countryName">
                {this.props.event.country.label}, {this.props.event.city}
              </span>
            </div>
            <span className="eventCardIntro">
              {this.props.event.intro && this.props.event.intro.length > 120
                ? this.props.event.intro.slice(0, 117) + "..."
                : this.props.event.intro}
            </span>
          </div>
        </div>
      </Link>
    );
  }
}

export default withRouter(EventCard);
