import React, { Component } from "react";

import { Link } from "react-router-dom";
import routes from "constants/routes";

const logo = "/assets/logo.svg";

class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-2 col-xl-2 text-md-left mb-3">
              <Link to={routes.HOME} className="icons">
                <img alt="Seascape Edition" src={logo} />
              </Link>
            </div>
            <div className="col-sm-12 col-md-4 col-xl-4 text-md-center mb-2">
              <Link to={routes.PROJECTS}>
                Research &amp; development projects
                <br />
                supported by public funding
              </Link>
            </div>
            <div className="col-sm-12 col-md-3 col-xl-3 text-md-center mb-2">
              <span>All rights reserved © Seascape Edition</span>
            </div>
            <div className="col-sm-12 col-md-3 col-xl-3 text-md-right mb-2">
              <Link className="mr-3" to={routes.COMMUNITY + "/#contactForm"}>
                Contact Us
              </Link>
              <Link to={routes.LEGAL}>Legal notice</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
