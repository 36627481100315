import React, { Component } from "react";

class CarouselCard extends Component {
  componentDidMount() {
    if (this.props.index === 0) {
      const line = document.getElementById("cardLine");
      line && line.classList.remove("carouselCardCurrentLineActive");
      setTimeout(() => line && line.classList.add("carouselCardCurrentLineActiveShort"), 100);
    }
  }

  render() {
    const background = this.props.active
      ? `url(${this.props.backgroundImage})`
      : `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${
          this.props.backgroundImage
        })`;

    return (
      <div
        className="carouselCard"
        onClick={() => this.props.onClick && this.props.onClick(this.props.index)}
        style={{
          background,
        }}
      >
        <div>
          <div className={`carouselCardLine${this.props.active ? "Active" : ""}`} />
          <div
            id="cardLine"
            className={`carouselCardCurrentLine ${this.props.className} ${
              this.props.active
                ? this.props.transform
                  ? "carouselCardCurrentLineActive"
                  : "carouselCardCurrentLineActiveStatic"
                : ""
            }`}
          />
        </div>
        <span className="carouseCardTitle">{this.props.title}</span>
      </div>
    );
  }
}

export default CarouselCard;
