// TODO:  Should add translations for error messages

function validateFieldLength(fieldValue, error) {
  if (!fieldValue || fieldValue.length <= 0) {
    let validationError = new Error(error);
    validationError.code = error;
    throw validationError;
  } else {
    return true;
  }
}

function validateEmailRegex(email, error) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase())) {
    let validationError = new Error(error);
    validationError.code = error;
    throw validationError;
  }
  return true;
}

export function validateEmail(value) {
  return (
    validateFieldLength(value, "Email field cannot be empty") &&
    validateEmailRegex(value, "Email format is not valid")
  );
}

export function validateSubject(value) {
  return validateFieldLength(value, "Email subject cannot be empty");
}

export function validateEmailContent(value) {
  return validateFieldLength(value, "Email message cannot be empty");
}

export function validateTeamName(value) {
  return validateFieldLength(value, "Team name cannot be empty");
}

export function validatePhoneNumber(value) {
  return validateFieldLength(value, "Phone number cannot be empty");
}

export function validateSailNumber(value) {
  return validateFieldLength(value, "Sail number cannot be empty");
}

export function validateSkipper(value) {
  return validateFieldLength(value, "Skipper name cannot be empty");
}

export function validateBoat(value) {
  return validateFieldLength(value, "Boat must be selected");
}

export function validateTermsAndConditions(value) {
  return validateFieldLength(
    value,
    "You have to agree with Terms and Conditions before registering to particiapte in event"
  );
}

export function validateRecaptcha(value) {
  return validateFieldLength(value, "You have to complete reCAPTCHA");
}
