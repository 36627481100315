import React, { Component } from "react";

class ShareButtons extends Component {
  render() {
    const { news } = this.props;
    const link = window.location.href;
    const mailLink = `mailto:?subject=${news.title} - Seascape&amp&body=Check this news! ${link}`;

    return (
      <div className="shareContainer">
        <h3>Share this</h3>
        <div className="shareButtonsContainer">
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              link +
              "&picture=" +
              news.featured_photo.medium +
              "&title=" +
              news.title +
              "&description=" +
              news.intro
            }
            target="_blank"
            rel="noopener noreferrer"
            title="Share on Facebook"
          >
            <div className="facebookShare">
              <i className="fab fa-facebook-f fa-lg" style={{ color: "#ffffff" }} />
            </div>
          </a>
          <a
            href={"https://twitter.com/share?&text=" + news.intro + "%0a" + link}
            target="_blank"
            rel="noopener noreferrer"
            title="Share on Twitter"
          >
            <div className="twitterShare">
              <i className="fab fa-twitter fa-lg" style={{ color: "#ffffff" }} />
            </div>
          </a>
          <a href={mailLink} title="Share by Email">
            <div className="mailShare">
              <i className="far fa-envelope fa-lg" style={{ color: "#ffffff" }} />
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default ShareButtons;
