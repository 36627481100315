import React, { Component } from "react";

import EventAbout from "../../components/EventAbout";
import EventDashboard from "../../components/EventDashboard";
import EventImpressions from "../../components/EventImpressions";
import EventMenu from "../../components/EventMenu";
import EventNews from "../../components/EventNews";
import EventStories from "../../components/EventStories";
import EventTeams from "../../components/EventTeams";
import { Helmet } from "react-helmet";
import NotFound from "../NotFound";
import SingleEventHero from "../../components/Heros/SingleEventHero";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class SingleEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
      activeMenuLink: "",
    };

    api.pullTwitterFeed();
    api.pullTeams();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var element = document.getElementById("navbarFixed");
    element.classList.remove("navbarFixedVisible");

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.eventId) {
      const eventId = nextProps.match.params.id;
      return { eventId };
    }
    return null;
  }

  handleScroll = () => {
    let activeSection = document.getElementsByTagName("section");

    for (var element of activeSection) {
      if (
        window.pageYOffset >= element.offsetTop - 150 &&
        window.pageYOffset <= element.offsetTop + element.clientHeight
      ) {
        this.setState({ activeMenuLink: element.id });
      }
    }
  };

  render() {
    let event = this.props.collections.fetchedCollections.events && {
      ...this.props.collections.events.all[this.state.eventId],
      id: this.state.eventId,
    };

    if (!event.title) {
      return <NotFound />;
    }

    return (
      <div className="singleEventContainer">
        <Helmet>
          <title> Seascape Edition | Event - {event.title}</title>
          <meta name="description" content={event.intro} />
        </Helmet>

        <section>
          <SingleEventHero event={event} />
        </section>

        <section className="eventMenuSection" id="EventMenu">
          <EventMenu active={this.state.activeMenuLink} event={event} />
        </section>

        {event.stories_events_id && (
            <section className="eventStoriesSection" id="Stories">
              <EventStories event={event} />
            </section>
        )}

        {event.twitter_tag && (
          <section className="eventDashboardSection" id="LiveFeed">
            <EventDashboard event={event} />
          </section>
        )}

        {event.news_events_id && (
          <section className="eventNewsSection" id="News">
            <EventNews event={event} />
          </section>
        )}

        <section className="eventTeamsSection" id="Teams">
          <EventTeams event={event} />
        </section>

        <section className="aboutEventSection" id="About">
          <EventAbout event={event} />
        </section>

        {event.impressions_gallery &&
          event.impressions_video &&
          (event.impressions_gallery.length === 4 ? (
            <section className="impressionsSection" id="Impressions">
              <EventImpressions event={event} />
            </section>
          ) : null)}
      </div>
    );
  }
}

export default withRouter(withCollections(SingleEvent));
