import React from "react";

function Multiselect({ field, data, onChange, errorMessage }) {
  return (
    <React.Fragment>
      <div
        className="registerFormSelectContainer"
        style={{ display: "flex", alignItems: "center" }}
      >
        <select
          className={`registerFormSelect${errorMessage ? " error" : ""}`}
          defaultValue={0}
          name={field.id}
          onChange={e => onChange({ ...data, [field.id]: e.target.value })}
        >
          <option disabled value={0}>
            {field.label}
          </option>
          {field.choices.map((choice, index) => (
            <option key={index} value={choice.value} required={field.isRequired}>
              {choice.text}
            </option>
          ))}
        </select>
        <i className="fas fa-chevron-down" />
        {field.isRequired && "*"}
      </div>
      {errorMessage && (
        <span className="registerFormErrorMessage">This field cannot be empty!</span>
      )}
    </React.Fragment>
  );
}

export default Multiselect;
