import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import _ from "lodash";
import date from "utils/date";
import routes from "constants/routes";

class NewsCard extends Component {
  render() {
    const { news } = this.props;
    const publishDate = date.getPublishDate(news.post_date);

    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);
    const {useLink=false, hideDate=false, hideText=false, external=false} = this.props;
    let link;

    if (external || useLink) {
      link = this.props.link;
    } else {
      link = `${this.props.news.post_type === "story" ? routes.STORIES : routes.NEWS}/${this.props.news.id}`;
    }

    return (
      <AnchorLink to={link} external={external}>
        <div id="newsCard">
          <div className="newsCardContainer">
            <div
              className="newsCardTop"
              style={{ background: `url(${
                news.thumbnail_photo && news.thumbnail_photo.thumbnail ? news.thumbnail_photo.thumbnail : news.featured_photo.thumbnail
              })` }}
            />
          </div>
          <div className="newsCardBottom">
            <div className="newsCardBottomIntro">
              {!news.community && <h4>{_.capitalize(this.props.news.post_type)}</h4>}
              {!(hideText || hideDate) ? <span>Published {editedPublishDate}</span> : null}
              <h3>{news.title}</h3>
            </div>
            {!hideText ?
              <div className="newsCardText">
                <span className="newsCardIntro">{news.intro}</span>
              </div>
              : null
            }
          </div>
        </div>
      </AnchorLink>
    );
  }
}

const AnchorLink = (props) => {
  if (props.external) {
    return <a href={props.to}>{props.children}</a>;
  }
  return <Link to={props.to}>{props.children}</Link>;
};

export default withRouter(NewsCard);
