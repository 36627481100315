import React, { Component } from "react";

import date from "utils/date";

import Breadcrumbs from "../../Breadcrumbs";

class SingleEventHero extends Component {
  render() {
    const { event } = this.props;
    const datum = date.getDate(event.start_date);
    const flag = `/assets/flags/${event.country && event.country.value}.svg`;

    return (
      <div
        className="singleEventHeroContainer"
        style={{
          background: `url(${event.featured_photo && event.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <div className="heroContentContainer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="eventsHeroInfoContainer">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                      <div className="info">
                        <img
                          src={flag}
                          alt={event.country && event.country.label}
                          className="countryFlag"
                        />
                        <span className="countryName">
                          {event.country && event.country.label}, {event.city}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                      <div className="info">
                        <i className="fas fa-calendar-alt" />
                        <span className="information">{datum}</span>
                      </div>
                    </div>

                    {event.information && (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="info">
                          <i className="fas fa-info-circle" />
                          <span className="information">{event.information}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-7">
                <h1>{event.title}</h1>
                <p>{event.intro}</p>
              </div>
            </div>
          </div>
        </div>
        <Breadcrumbs />
      </div>
    );
  }
}

export default SingleEventHero;
