import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";
import HorizontalScroll from "components/Scrollers/Horizontal";
import StoryCard from "components/Cards/StoryCard";

class EventStories extends Component {
  render() {
    const { event } = this.props;

    const stories =
      this.props.collections.fetchedCollections.stories &&
      event.stories_events_id &&
      event.stories_events_id.map(storyId => {
        const story = this.props.collections.stories[storyId];
        story.id = storyId;
        return {
          ...this.props.collections.stories[storyId],
          id: storyId,
        };
      });

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="eventNewsContainer">
              <h2>Event Stories</h2>
            </div>
          </div>
          {stories.length === 0 ? (
            <div className="col-12">
              <p className="noNewsOrStories">There are no stories about this event yet.</p>
            </div>
          ) : (
            <StoryScrollSection list={stories} filterBy={story => story} />
          )}
        </div>
      </div>
    );
  }
}

const StoryScrollSection = ({ list, filterBy }) => (
  <HorizontalScroll index={0}>
    {list &&
      list.filter(filterBy).map(story => (
        <div className="storyScrollSectionCard" key={story.id}>
          <StoryCard story={story} />
        </div>
      ))}
  </HorizontalScroll>
);

export default withCollections(EventStories);
