import React, { Component } from "react";

import Masonry from "react-masonry-component";
import NewsCard from "../../components/Cards/NewsCard";
import withCollections from "higherOrderComponents/withCollections";

class KnowledgeBaseNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedNews: 6,
    };
  }
  render() {
    const {knowledgeBase} = this.props;

    return (
        <React.Fragment>
            <div className="offset-1" />
            <div className="col-10">
                <Masonry>
                    {this.props.collections.fetchedCollections.news && knowledgeBase && knowledgeBase.news.slice(0, this.state.loadedNews).map((newsId, index) => {
                        const news = this.props.collections.news[newsId];
                        news.id = newsId;
                        return (
                            <div key={index} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                <NewsCard news={news}/>
                            </div>
                            );
                        })
                    }
                    </Masonry>
                    {Object.keys(knowledgeBase && knowledgeBase.news).length > this.state.loadedNews ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="loadMoreButtonContainer">
                                    <button
                                        className="load-more-button"
                                        onClick={() => {
                                            this.setState({ loadedNews: this.state.loadedNews + 6 });
                                        }}
                                    >
                                        Load more news
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default withCollections(KnowledgeBaseNews);
