import React, { Component } from "react";
import {
  validateEmail,
  validateEmailContent,
  validateRecaptcha,
  validateSubject,
} from "utils/error/validators";

import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import withCollections from "higherOrderComponents/withCollections";

const fieldValidation = {
  email: "",
  subject: "",
  emailContent: "",
  gToken: null,
};

class CommunityImpressions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...fieldValidation,
      fieldValidation: fieldValidation,
      message: "",
      status: "",
      title: "",
      sending: false,
    };
  }

  validateField(fieldName, value, validator = null) {
    let fieldValid = "";
    if (!validator) return fieldValid;

    try {
      validator(value);
      this.setFieldValidation(fieldName);
    } catch (err) {
      this.showError();
      fieldValid = err.message;
    } finally {
      this.setFieldValidation(fieldName, fieldValid);
      return { [fieldName]: fieldValid };
    }
  }

  setFieldValidation(fieldName, errorMessage = "") {
    this.setState({
      fieldValidation: {
        ...this.state.fieldValidation,
        [fieldName]: errorMessage,
      },
    });
  }

  validateForm() {
    const validation = Object.assign(
      {},
      this.validateField("gToken", this.state.gToken, validateRecaptcha),
      this.validateField("subject", this.state.subject, validateSubject),
      this.validateField(
        "emailContent",
        this.state.emailContent,
        validateEmailContent
      ),
      this.validateField("email", this.state.email, validateEmail)
    );
    this.setState({
      fieldValidation: { ...this.state.fieldValidation, ...validation },
    });
    const validationContainsErrorMessages = Object.keys(validation).some(
      (key) => validation[key].length
    );
    if (!validationContainsErrorMessages) {
      return true;
    }
    return false;
  }

  onChange(fieldName, value, validator) {
    this.setState({ [fieldName]: value });

    return this.validateField(fieldName, value, validator);
  }

  contactFormSending = () => {
    this.setState({ sending: true });
    const formData = new FormData();
    formData.set("email", this.state.email);
    formData.set("subject", this.state.subject);
    formData.set("emailContent", this.state.emailContent);

    return Axios({
      method: "POST",
      url: "https://seascape-edition.com/seascape/wp-json/post/contact-form",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({
            status: "success",
            title: "Success",
            message: "You have successfully send email!",
            email: "",
            subject: "",
            emailContent: "",
          });
          setTimeout(
            () => this.setState({ status: "", message: "", title: "" }),
            3500
          );
        } else {
          this.setState({
            status: "danger",
            message: "Error while trying to send email!",
            title: "Failed",
          });
          setTimeout(
            () => this.setState({ status: "", message: "", title: "" }),
            3500
          );
        }
      })
      .catch(() => {
        this.setState({
          status: "danger",
          message: "Error while trying to send email!",
          title: "Failed",
        });
        setTimeout(
          () => this.setState({ status: "", message: "", title: "" }),
          3500
        );
      })
      .then(() => this.setState({ sending: false }));
  };

  render() {
    const community = this.props.community;

    return (
      <React.Fragment>
        <div className="communityImpressionsContainer">
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-12">
              <div
                className="communityImpressionImageContainer"
                style={{
                  background: `url(${
                    community.first_image && community.first_image.url
                  })`,
                }}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="communityImpressionTextContainer">
                <div className="communityImpressionText">
                  <h2>Engage and tell a story</h2>
                  <h4>{community.impressions_text}</h4>
                  <div className="hashtagCommunity">
                    <i
                      className="fas fa-hashtag fa-3x"
                      style={{ color: "#ed1c24" }}
                    />{" "}
                    <span className="hashtagText">
                      {community.impressions_hashtag}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div
                className="communityImpressionImageContainer"
                style={{
                  background: `url(${
                    community.second_image && community.second_image.url
                  })`,
                }}
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-12">
              <div
                className="communityImpressionTextContainer"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="communityImpressionText">
                  <i
                    className="far fa-envelope"
                    style={{ color: "#000", marginBottom: "20px" }}
                  />
                  <p>Send us your story</p>
                  <h3>{community.impressions_text_second}</h3>
                  <button className="button-primary">
                    <a href={"mailto:info@thinkseascape.com"}>
                      Send us your story
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div
                className="communityImpressionImageContainerSmall"
                style={{
                  background: `url(${
                    community.third_image && community.third_image.url
                  })`,
                }}
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              {this.state.status && (
                <div
                  className={`alert alert-${this.state.status} subscribeAlert`}
                >
                  <strong>{this.state.title}!</strong> {this.state.message}
                </div>
              )}
              <div
                id="contact"
                className="communityFormContainer"
                style={{
                  background: `url(${
                    community.fourth_image && community.fourth_image.url
                  })`,
                }}
              >
                <div className="communityFormOverlayContainer" />
                <div className="communityFormTextContainer">
                  <div className="communityFormInnerTextContainer">
                    <h2>{community.form_title}</h2>
                    <p>{community.form_subtitle}</p>

                    <InputWithErrorCheck
                      errorMessage={this.state.fieldValidation.email}
                    >
                      <input
                        className="communityFormInput"
                        placeholder="Enter your email"
                        value={this.state.email}
                        onChange={({ target }) =>
                          this.onChange("email", target.value, validateEmail)
                        }
                      />
                    </InputWithErrorCheck>
                    <InputWithErrorCheck
                      errorMessage={this.state.fieldValidation.subject}
                    >
                      <input
                        className="communityFormInput"
                        placeholder="Enter subject"
                        value={this.state.subject}
                        onChange={({ target }) =>
                          this.onChange(
                            "subject",
                            target.value,
                            validateSubject
                          )
                        }
                      />
                    </InputWithErrorCheck>
                    <InputWithErrorCheck
                      errorMessage={this.state.fieldValidation.emailContent}
                    >
                      <textarea
                        className="communityFormInputArea"
                        placeholder="Your message"
                        value={this.state.emailContent}
                        onChange={({ target }) =>
                          this.onChange(
                            "emailContent",
                            target.value,
                            validateEmailContent
                          )
                        }
                      />
                    </InputWithErrorCheck>
                    <InputWithErrorCheck
                      errorMessage={this.state.fieldValidation.gToken}
                    >
                      <ReCAPTCHA
                        ref={(ref) => (this.recaptchaRef = ref)}
                        className="registerFormRecaptcha"
                        sitekey="6Ld1v7IUAAAAAJ3VRQoKbOZXuy-EvkVoHCjRWfM0"
                        onChange={(gToken) =>
                          this.onChange("gToken", gToken, validateRecaptcha)
                        }
                      />
                    </InputWithErrorCheck>
                    <button
                      className={`button-primary ${
                        this.state.status ||
                        !this.state.email ||
                        !this.state.subject ||
                        !this.state.emailContent ||
                        !this.state.gToken
                          ? "disabled"
                          : ""
                      }`}
                      onClick={this.contactFormSending}
                      disabled={
                        !this.state.email ||
                        !this.state.subject ||
                        !this.state.emailContent ||
                        !this.state.gToken
                      }
                    >
                      SEND{" "}
                      {this.state.sending && (
                        <i className="fa fa-spinner fa-spin" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const InputWithErrorCheck = (props) => (
  <React.Fragment>
    {props.children}
    {props.errorMessage && (
      <span className="registerFormErrorMessage">{props.errorMessage}</span>
    )}
  </React.Fragment>
);

export default withCollections(CommunityImpressions);
