import React, { Component } from 'react';

import withCollections from 'higherOrderComponents/withCollections';
import withTwitterModal from '../../higherOrderComponents/withTwitterModal';

class EventDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tweets: null,
      trackerImage: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (!prevState.tweets && nextProps.collections.fetchedCollections.twitter) {
      newState.tweets = Object.values(nextProps.collections.twitter).filter(
        tweet => tweet.hashtag === nextProps.event.twitter_tag,
      );
    }

    return newState;
  }

  removeLeadingZeros = string => string.slice(0, string.length - 3);

  isCurrentActivity = activity =>
    new Date(`${activity.start_date} ${activity.start_time}`) <= new Date() &&
    new Date() <= new Date(`${activity.start_date} ${activity.end_time}`);

  isBetweenActivities = (first, second) =>
    new Date(`${first.start_date} ${first.end_time}`) <= new Date() &&
    new Date() <= new Date(`${second.start_date} ${second.start_time}`);

  scrollToActivity() {
    const scheduleScroll = document.getElementById('schedule');
    const currentActivities =
      scheduleScroll && scheduleScroll.getElementsByClassName('fa-caret-right');

    if (scheduleScroll && currentActivities.length) {
      scheduleScroll.scrollTo(0, currentActivities[0].offsetTop - scheduleScroll.offsetTop - 110);
    }
  }

  getCurrentActivities = schedule => {
    const allEventsMap = {};
    const allEventsArray = [];

    Object.keys(schedule).map(day => {
      allEventsMap[day] = {};
      return allEventsArray.push(
        ...Object.values(schedule[day]).map(activity => ({
          ...activity,
          isCurrent: this.isCurrentActivity(activity),
        })),
      );
    });

    allEventsArray.forEach(event => (allEventsMap[event.start_date][event.start_time] = event));

    return {
      allEvents: allEventsMap,
      currentEvents: allEventsArray.filter(event => event.isCurrent),
    };
  };

  renderSchedule(event) {
    const get = this.getCurrentActivities(event.schedule);
    this.scrollToActivity();

    return (
      <div className="twitterFeedContainer" id="schedule">
        <div className="scheduleRow">
          {Object.keys(event.schedule).map((day, index) => {
            const allEventsArray = Object.values(get.allEvents[day]);

            return (
              <React.Fragment key={index}>
                <p className="scheduleTitle">{new Date(day).toDateString()}</p>
                {allEventsArray.map((activity, index) => (
                  <React.Fragment key={index}>
                    <div className="scheduleActivity">
                      <span>
                        {this.removeLeadingZeros(activity.start_time)} -
                        {this.removeLeadingZeros(activity.end_time)}
                      </span>
                      <p className={activity.isCurrent ? 'scheduleActivityNameActive' : ''}>
                        {activity.isCurrent ? <i className="fas fa-caret-right fa-xs"></i> : null}{' '}
                        {activity.activity}
                      </p>
                    </div>
                    {!get.currentEvents.length &&
                      index < get.currentEvents.length &&
                      this.isBetweenActivities(
                        allEventsArray[index],
                        allEventsArray[index + 1],
                      ) && (
                        <div className="scheduleBetweenActivities">
                          <i className="fas fa-caret-right fa-xs"></i>
                        </div>
                      )}
                  </React.Fragment>
                ))}
                {Object.keys(event.schedule).length !== index + 1 ? <hr /> : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { event } = this.props;

    const twitterUrl = (user_name, post_id) => `https://twitter.com/${user_name}/status/${post_id}`;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {event.tracker_link ? (
                <div className="liveTrackerContainer">
                  <iframe title="liveTracker" className="liveTracker" src={event.tracker_link} />
                </div>
            ) : (
                <div className="dashboardButtonDisabled mb-5">
                  <button className="button-primary">
                  <span className="liveButtonText">
                    {event.tracker_label}
                  </span>
                  </button>
                </div>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2>Live feed</h2>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="twitterContainer">
              <div className="feedTitleContainer">
                <i className="fab fa-twitter" />
                <p className="feedTitle">Event twitter feed</p>
                <span>{event.twitter_tag ? `#${event.twitter_tag}` : null}</span>
              </div>
              <div className="twitterFeedContainer">
                {this.state.tweets &&
                  this.state.tweets.map(tweet => (
                    <TwitterPost
                      author={tweet.user_name}
                      authorUserName={tweet.user_screen_name}
                      content={tweet.post_content}
                      key={tweet.id}
                      setTwitterUrl={this.props.setTwitterUrl}
                      twitterImage={tweet.image_url}
                      url={twitterUrl(tweet.user_screen_name, tweet.id)}
                      userAvatar={tweet.user_profile_image}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            {event.schedule ? (
                <div className="scheduleContainer">
                  <div className="feedTitleContainer">
                    <i className="fas fa-calendar-alt" />
                    <p className="feedTitle">Schedule</p>
                  </div>
                  {this.renderSchedule(event)}
                </div>
            ) : (
                <div className="dashboardButtonDisabled dashboardButtonFirst">
                  <button className="button-primary">
                    <i className="fas fa-calendar-alt"></i>
                    <span className="liveButtonText">Schedule will appear here when available</span>
                  </button>
                </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const TwitterPost = ({
  author,
  authorUserName,
  content,
  setTwitterUrl,
  twitterImage,
  url,
  userAvatar,
}) => {
  [
    [/http.{0,1}:\/\/t.co.*/, ''],
    ['&amp;', '&'],
  ].forEach(([searchValue, newValue]) => (content = content.replace(searchValue, newValue)));
  return (
    <div className="twitterFeedCard" onClick={() => setTwitterUrl(url)}>
      <div className="twitterFeedCardContainer">
        <div className="twitterUserContainer">
          <div className="row">
            <div className="col-md-2 col-3">
              <div className="twitterProfilePicContainer">
                <img src={userAvatar} alt={authorUserName} className="twitterProfilePic" />
              </div>
            </div>
            <div className="col-md-10 col-9">
              <div className="twitterFeedAuthor">
                <span>{author}</span> <span className="authorUsername">@{authorUserName}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="twitterContentContainer">
          <div className="row">
            <div className="col-12">
              {twitterImage ? (
                <img src={twitterImage} alt="twitterPhoto" className="twitterPhoto" />
              ) : null}
              <div className="twitterFeedTextContainer">
                <div className="twitterFeedContent">
                  <span>{content}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTwitterModal(withCollections(EventDashboard));
