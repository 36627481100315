import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";
import Breadcrumbs from "../../Breadcrumbs";

class SingleNewsHero extends Component {
  render() {
    const { news } = this.props;

    return (
      <div
        className="singleNewsHeroContainer"
        style={{
          background: `url(${news.featured_photo && news.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <div className="heroContentContainer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xl-8">
                <h1>{news.title}</h1>
                <p>
                  {news.intro && news.intro.length > 190
                    ? news.intro.slice(0, 187) + "..."
                    : news.intro}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Breadcrumbs community={news.community} />
      </div>
    );
  }
}

export default withCollections(SingleNewsHero);
