import React, { Component } from "react";

import Masonry from "react-masonry-component";
import NewsCard from "../Cards/NewsCard";
import withCollections from "higherOrderComponents/withCollections";

class VlogNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedNews: 8,
    };
  }
  render() {
    const posts = this.props.collections.fetchedCollections.news && this.props.collections.fetchedCollections.stories ? [
        ...Object.keys(this.props.collections.news).filter(newsId => this.props.collections.news[newsId].vlog),
        ...Object.keys(this.props.collections.stories).filter(storyId => this.props.collections.stories[storyId].vlog)
    ] : [];
      
    return (
        <React.Fragment>
            <div className="col-12">
                <Masonry>
                    {posts.slice(0, this.state.loadedNews).map((postId, index) => {
                        const post = this.props.collections.news[postId] || this.props.collections.stories[postId];
                        post.id = postId;
                        return (
                            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
                                <NewsCard hideText news={post}/>
                            </div>
                        )
                    })}
                    </Masonry>
                    {posts.length > this.state.loadedNews ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="loadMoreButtonContainer">
                                    <button
                                    className="load-more-button"
                                    onClick={() => {
                                        this.setState({ loadedNews: this.state.loadedNews + 6 });
                                    }}
                                    >
                                    Load more news
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <h1>fix this</h1>
                </div>
            </React.Fragment>
        );
    }
}

export default withCollections(VlogNews);
