import moment from "moment";

function getDateTimeNow(date = Date.now()) {
  return moment(date).format("X");
}

function getMoment(date, format) {
  return moment(date, format);
}

function getDay(date) {
  const datum = moment(date, "DD/MM/YYYY");
  const day = datum.date();
  return day;
}

function getMonth(date) {
  const datum = moment(date, "DD/MM/YYYY");
  const month = datum.format("MMM");
  return month;
}

function getDate(date) {
  const datum = moment(date, "DD/MM/YYYY").format("MMMM Do YYYY");
  const day = moment(date, "DD/MM/YYYY").format("ddd");
  const fullDate = day + ", " + datum;
  return fullDate;
}

function getDateTime(date) {
  const datum = moment(date, "YYYY-MM-DD HH:mm:ss").format("x");
  return datum;
}

function getPublishDate(date) {
  const publishDate = moment(date, "YYYY-MM-DD HH:mm:ss").format("MMM Do YY");
  return publishDate;
}
export default {
  getMoment,
  getDateTimeNow,
  getDate,
  getDay,
  getMonth,
  getDateTime,
  getPublishDate,
};
