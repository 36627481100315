import React, { Component } from "react";

import { Helmet } from "react-helmet";
import NewsList from "../../components/NewsList";

class News extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="newsContainer">
        <Helmet>
          <title> Seascape Edition | News</title>
          <meta
            name="description"
            content="News and updates from the sailing world. "
          />
        </Helmet>
        <section id="navbarTrigger">
          <NewsList />
        </section>
      </div>
    );
  }
}

export default News;
