import React, { Component } from "react";

import EventNewsCard from "../Cards/EventNewsCard";
import withCollections from "higherOrderComponents/withCollections";

class EventNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadMore: false,
    };
  }

  render() {
    const { event } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="eventNewsContainer">
              <h2>Event News</h2>
            </div>
          </div>
          {(this.props.collections.fetchedCollections.news && event.news_events_id).length === 0 ? (
            <div className="col-12">
              <p className="noNewsOrStories">There are no news about this event yet.</p>
            </div>
          ) : (
            this.props.collections.fetchedCollections.news &&
            event.news_events_id &&
            event.news_events_id.map((newsId, index) => {
              // eslint-disable-next-line
              if (this.state.loadMore === false && index < 3 || this.state.loadMore === true) {
                const news = this.props.collections.news[newsId];
                news.id = newsId;
                return (
                  <div className="col-md-6 col-xl-4" key={index}>
                    <EventNewsCard news={news} />
                  </div>
                );
              }
              return null;
            })
          )}
        </div>
        {event.news_events_id.length > 3 ? (
          <div className="row">
            <div className="col-12">
              <div className="loadMoreEventNewsContainer">
                {this.state.loadMore ? (
                  <button
                    className="load-more-button"
                    onClick={() => {
                      this.setState({ loadMore: false });
                    }}
                  >
                    Show less news
                  </button>
                ) : (
                  <button
                    className="load-more-button"
                    onClick={() => {
                      this.setState({ loadMore: true });
                    }}
                  >
                    Show more news
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withCollections(EventNews);
