import React, { Component } from "react";

class AboutCard extends Component {
  openGallery = (props) => {
    this.props.onClick({
      name: props.name,
      photo: props.photo,
      featured_photo: props.featured_photo,
    });
  };

  render() {
    return (
      <div
        onClick={() => this.openGallery(this.props)}
        className="aboutCardContainer"
      >
        <div className="aboutCard">
          <img
            className="aboutCardImage"
            alt={this.props.name}
            src={this.props.photo.medium}
          />
          {/*<div className="aboutCardContent">*/}
          {/*  <span>{this.props.job_title}</span>*/}
          {/*  <span className="aboutCardQuote">{this.props.quote}</span>*/}
          {/*</div>*/}
          <div className="aboutCardName">
            <span>{this.props.name}</span>
            <span className="aboutCardJobTitle">{this.props.job_title}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutCard;
