import postTypes from "../../constants/postTypes";

let initialState = {
  fetchedCollections: {},
};
Object.values(postTypes).forEach(postType => (initialState[postType] = {}));
Object.values(postTypes).forEach(postType => (initialState.fetchedCollections[postType] = false));

export default function postReducer(state, action) {
  if (state === undefined) {
    return initialState;
  }
  var newState = Object.assign({}, state);

  switch (action.type) {
    case "ADD_COLLECTION_TO_FETCHED": {
      newState.fetchedCollections = Object.assign({}, state.fetchedCollections, {
        [action.collectionName]: true,
      });
      break;
    }
    case "ADD_DOCUMENTS_TO_COLLECTION": {
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
      newState[action.collectionName] = { ...newState[action.collectionName], ...action.documents };
      break;
    }
    case "ADD_DOCUMENTS_TO_AGGREGATED_COLLECTION": {
      // level 1
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);

      // level 2
      if (!newState[action.collectionName][action.aggregatorValue])
        newState[action.collectionName][action.aggregatorValue] = {};
      else
        newState[action.collectionName][action.aggregatorValue] = Object.assign(
          {},
          state[action.collectionName][action.aggregatorValue]
        );
      newState[action.collectionName][action.aggregatorValue] = {
        ...newState[action.collectionName][action.aggregatorValue],
        ...action.documents,
      };
      break;
    }
    case "UPDATE_DOCUMENT_IN_COLLECTION": {
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
      newState[action.collectionName][action.docId] = Object.assign(
        {},
        state[action.collectionName][action.docId]
      );
      newState[action.collectionName][action.docId] = {
        ...newState[action.collectionName][action.docId],
        ...action.updatedDocument,
      };
      break;
    }
    case "UPDATE_DOCUMENT_IN_AGGREGATED_COLLECTION": {
      // level 1
      newState[action.collectionName] = Object.assign({}, newState[action.collectionName]);

      // level 2
      if (!newState[action.collectionName][action.aggregatorValue])
        newState[action.collectionName][action.aggregatorValue] = {};

      newState[action.collectionName][action.aggregatorValue] = Object.assign(
        {},
        newState[action.collectionName][action.aggregatorValue]
      );

      // level 3
      if (!newState[action.collectionName][action.aggregatorValue][action.docId])
        newState[action.collectionName][action.aggregatorValue][action.docId] = {};

      newState[action.collectionName][action.aggregatorValue][action.docId] = Object.assign(
        {},
        newState[action.collectionName][action.aggregatorValue][action.docId]
      );

      // level 4
      newState[action.collectionName][action.aggregatorValue][action.docId] = {
        ...newState[action.collectionName][action.aggregatorValue][action.docId],
        ...action.updatedDocument,
      };
      break;
    }

    case "DELETE_DOCUMENT_FROM_COLLECTION": {
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
      delete newState[action.collectionName][action.docId];
      break;
    }

    case "DELETE_DOCUMENT_FROM_AGGREGATED_COLLECTION": {
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
      if (newState[action.collectionName][action.aggregatorValue]) {
        newState[action.collectionName][action.aggregatorValue] = Object.assign(
          {},
          newState[action.collectionName][action.aggregatorValue]
        );
        delete newState[action.collectionName][action.aggregatorValue][action.docId];
      }
      break;
    }

    case "UPDATE_OBJECT_FIELD_IN_DOCUMENT": {
      newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
      newState[action.collectionName][action.docId] = Object.assign(
        {},
        newState[action.collectionName][action.docId]
      );
      newState[action.collectionName][action.docId][action.fieldName] = Object.assign(
        {},
        newState[action.collectionName][action.docId][action.fieldName]
      );

      if (action.fieldValue === null) {
        delete newState[action.collectionName][action.docId][action.fieldName][action.fieldKey];
      } else {
        newState[action.collectionName][action.docId][action.fieldName][action.fieldKey] =
          action.fieldValue;
      }
      break;
    }

    // case "DELETE_OBJECT_KEY_FROM_DOCUMENT": {
    //   newState[action.collectionName] = Object.assign({}, state[action.collectionName]);
    //   newState[action.collectionName][action.docId] = Object.assign(
    //     {},
    //     newState[action.collectionName][action.docId]
    //   );
    //   newState[action.collectionName][action.docId][action.fieldName] = Object.assign(
    //     {},
    //     newState[action.collectionName][action.docId][action.fieldName]
    //   );

    //   delete newState[action.collectionName][action.docId][action.fieldName][action.fieldKey];
    //   break;
    // }

    case "RESET_STORE":
      newState = initialState;
      break;

    default:
      break;
  }
  return newState;
}
