import React, { Component } from "react";

class EventImpressions extends Component {
  render() {
    const { event } = this.props;

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-8 offset-2">
              <h2>Impressions</h2>
            </div>
          </div>
        </div>
        <div className="impressionsContainer">
          <div className="row no-gutters">
            <div className="col-mdlg-4">
              <div
                className="impressionImageContainer"
                style={{
                  background: `url(${event.impressions_gallery &&
                    event.impressions_gallery[0].url})`,
                }}
              />
            </div>
            <div className="col-mdlg-4">
              <div className="impressionTextContainer">
                <h4>PARTICIPANT TESTIMONIALS</h4>
                <h3>"{event.impressions_quotes[0].impressions_quote_one}"</h3>
              </div>
            </div>
            <div className="col-mdlg-4">
              <div
                className="impressionImageContainer"
                style={{
                  background: `url(${event.impressions_gallery &&
                    event.impressions_gallery[1].url})`,
                }}
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-mdlg-4">
              <div className="impressionTextContainer">
                <h4>PARTICIPANT TESTIMONIALS</h4>
                <h3>"{event.impressions_quotes[0].impressions_quote_two}"</h3>
              </div>
            </div>
            <div className="col-mdlg-8">
              <div className="impressionVideoContainer">
                <iframe
                  title="video"
                  className="player"
                  type="text/html"
                  width="100%"
                  height="100%"
                  src={"https://www.youtube.com/embed/" + event.impressions_video}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-mdlg-4">
              <div
                className="impressionImageContainer"
                style={{
                  background: `url(${event.impressions_gallery &&
                    event.impressions_gallery[2].url})`,
                }}
              />
            </div>
            <div className="col-mdlg-4">
              <div
                className="impressionImageContainer"
                style={{
                  background: `url(${event.impressions_gallery &&
                    event.impressions_gallery[3].url})`,
                }}
              />
            </div>
            <div className="col-mdlg-4">
              <div className="impressionTextContainer">
                <h4>PARTICIPANT TESTIMONIALS</h4>
                <h3>"{event.impressions_quotes[0].impressions_quote_three}"</h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EventImpressions;
