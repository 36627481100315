import React, { Component } from "react";

import Breadcrumbs from "../../Breadcrumbs";

class SingleBoatHero extends Component {
  render() {
    const { boat } = this.props;

    return (
      <div
        className="singleEventHeroContainer"
        style={{
          background: `url(${boat.featured_photo && boat.featured_photo.large})`,
        }}
      >
        <div className="heroOverlay" />
        <div className="heroContentContainer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xl-7">
                <h1>{boat.title}</h1>
                <p>{boat.intro}</p>
              </div>
            </div>
          </div>
        </div>
        <Breadcrumbs />
      </div>
    );
  }
}

export default SingleBoatHero;
