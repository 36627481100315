import React, { Component } from "react";

import AnchorLink from "react-anchor-link-smooth-scroll";

class BoatMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.active,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.active && prevState.active !== nextProps.active) {
      return { active: nextProps.active };
    }
    return {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", this.handleMenuScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMenuScroll);
  }

  handleMenuScroll = () => {
    let activeMenuLink = document.getElementsByClassName("eventMenuLinkActive");

    activeMenuLink[0] &&
      document.getElementById("eventMenuContainer").scroll({
        left: activeMenuLink[0].offsetLeft - activeMenuLink[0].clientWidth,
      });
  };

  render() {    
    return (
      <div className="eventMenuContainer" id="eventMenuContainer">
        <AnchorLink
          offset="100"
          href="#Features"
          onClick={() => this.setState({ active: "Features" })}
          className={`${
            this.state.active === "Features" ? "eventMenuLinkActive" : "eventMenuLink"
          }`}
        >
          Features
        </AnchorLink>
        {/*<AnchorLink*/}
        {/*  offset="100"*/}
        {/*  href="#Specs"*/}
        {/*  onClick={() => this.setState({ active: "Specs" })}*/}
        {/*  className={`${*/}
        {/*    this.state.active === "BoatSpecs" ? "eventMenuLinkActive" : "eventMenuLink"*/}
        {/*  }`}*/}
        {/*>*/}
        {/*  Boat Specifications*/}
        {/*</AnchorLink>*/}
        <AnchorLink
          offset="100"
          href="#StoriesNews"
          onClick={() => this.setState({ active: "StoriesNews" })}
          className={`${this.state.active === "StoriesNews" ? "eventMenuLinkActive" : "eventMenuLink"}`}
        >
          Stories &amp; News
        </AnchorLink>
        <AnchorLink
          offset="100"
          href="#OwnerReviews"
          onClick={() => this.setState({ active: "OwnerReviews" })}
          className={`${
            this.state.active === "OwnerReviews" ? "eventMenuLinkActive" : "eventMenuLink"
          }`}
        >
          Owner Reviews
        </AnchorLink>
        <AnchorLink
          offset="100"
          href="#Events"
          onClick={() => this.setState({ active: "Events" })}
          className={`${this.state.active === "Events" ? "eventMenuLinkActive" : "eventMenuLink"}`}
        >
          Events
        </AnchorLink>
        <AnchorLink
          offset="100"
          href="#Videos"
          onClick={() => this.setState({ active: "Videos" })}
          className={`${this.state.active === "Videos" ? "eventMenuLinkActive" : "eventMenuLink"}`}
        >
          Videos
        </AnchorLink>
      </div>
    );
  }
}

export default BoatMenu;
