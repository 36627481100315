import React, { Component } from "react";
import { Link } from "react-router-dom";

import AnchorLink from "react-anchor-link-smooth-scroll";

import date from "utils/date";

class EventMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.active,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.active && prevState.active !== nextProps.active) {
      return { active: nextProps.active };
    }
    return {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", this.handleMenuScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMenuScroll);
  }

  handleMenuScroll = () => {
    let activeMenuLink = document.getElementsByClassName("eventMenuLinkActive");

    activeMenuLink[0] &&
      document.getElementById("eventMenuContainer").scroll({
        left: activeMenuLink[0].offsetLeft - activeMenuLink[0].clientWidth,
      });
  };

  render() {
    const event = this.props.event;

    const currentDate = date.getDateTimeNow();
    const startDate = date.getMoment(event.start_date, "DD/MM/YYYY").format("X");

    return (
      <div className="eventMenuContainer" id="eventMenuContainer">
        {event.stories_events_id && (
            <AnchorLink
                offset="100"
                href="#Stories"
                onClick={() => this.setState({ active: "Stories" })}
                className={`${
                    this.state.active === "Stories" ? "eventMenuLinkActive" : "eventMenuLink"
                }`}
            >
              Event Stories
            </AnchorLink>
        )}
        {event.twitter_tag && (
          <AnchorLink
            offset="100"
            href="#LiveFeed"
            onClick={() => this.setState({ active: "LiveFeed" })}
            className={`${
              this.state.active === "LiveFeed" ? "eventMenuLinkActive" : "eventMenuLink"
            }`}
          >
            Live feed
          </AnchorLink>
        )}
        {event.news_events_id && (
          <AnchorLink
            offset="100"
            href="#News"
            onClick={() => this.setState({ active: "News" })}
            className={`${this.state.active === "News" ? "eventMenuLinkActive" : "eventMenuLink"}`}
          >
            Event News
          </AnchorLink>
        )}
        <AnchorLink
          offset="100"
          href="#Teams"
          onClick={() => this.setState({ active: "Teams" })}
          className={`${this.state.active === "Teams" ? "eventMenuLinkActive" : "eventMenuLink"}`}
        >
          Teams
        </AnchorLink>
        <AnchorLink
          offset="100"
          href="#About"
          onClick={() => this.setState({ active: "About" })}
          className={`${this.state.active === "About" ? "eventMenuLinkActive" : "eventMenuLink"}`}
        >
          About the event
        </AnchorLink>
        {event.impressions_gallery &&
          event.impressions_video &&
          (event.impressions_gallery.length === 4 ? (
            <AnchorLink
              offset="100"
              href="#Impressions"
              onClick={() => this.setState({ active: "Impressions" })}
              className={`${
                this.state.active === "Impressions" ? "eventMenuLinkActive" : "eventMenuLink"
              }`}
            >
              Impressions
            </AnchorLink>
          ) : null)}
        <a
          offset="100"
          href={(event.results && event.results.url) || null}
          rel="noopener noreferrer"
          target="_blank"
          className={`${
            event.results && event.results.url ? "eventMenuLink" : "eventMenuLink disabledLink"
          }`}
        >
          Results
        </a>
        {event.form_id && event.applications_open && currentDate <= startDate && (
          <div className="buttonContainer eventMenuLink">
            <Link to={"/events/" + event.id + "/register"}>
              <button className="button-primary">Join Event</button>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default EventMenu;
