const routes = {
  ABOUT: "/about",
  BOAT_MODELS: "/boat-models",
  BOATS: "/boats",
  COMMUNITY: "/community",
  EVENTS: "/events",
  HOME: "/",
  KNOWLEDGE_BASE: "/knowledge-base",
  LEGAL: "/legal",
  OWNERS_CLUBS: "/owners-clubs",
  NEWS: "/news",
  POPUP: "/popup",
  PROJECTS: "/public-funded-projects",
  STORIES: "/stories",
  TAGS: "/tags",
  VLOG: "/vlog",
  WELCOME_ABOARD: "/welcome-aboard",
  FORUM: "https://forum.seascape-edition.com/",
  URL: "https://seascape-edition.com/",
};

export default routes;
