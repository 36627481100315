import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import routes from "constants/routes";
import withCollections from "higherOrderComponents/withCollections";

const initPath = { name: "Home", location: "/" };

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: null,
      path: [initPath],
      url: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const fetchedEvents = nextProps.collections.fetchedCollections.events;
    const fetchedNews = nextProps.collections.fetchedCollections.news;
    const fetchedStories = nextProps.collections.fetchedCollections.stories;

    const { teams } = nextProps.collections;
    const events = nextProps.collections.events.all;
    const { url, params } = nextProps.match;

    if (
      fetchedEvents &&
      fetchedNews &&
      fetchedStories &&
      ((!prevState.url && nextProps.match.url !== prevState.url) ||
        nextProps.match.url.replace(/\//g, "") !== prevState.url.replace(/\//g, ""))
    ) {
      const urlPaths = url.split("/");
      urlPaths.shift();

      const postType = urlPaths[0];
      const id = params.id;
      const teamId = params.team_id;
      const collection = nextProps.collections[postType];

      if (postType === "news") {
        if (collection[id]) {
          if (collection[id].news_events_id && collection[id].news_events_id.length) {
            // If news is linked to any event
            newState.path = [
              initPath,
              { name: "Events", location: routes.EVENTS },
              {
                name: events[collection[id].news_events_id[0]].title,
                location: `${routes.EVENTS}/${collection[id].news_events_id[0]}`,
              },
              {
                name: collection[id].title,
                location: id,
                related: true,
              },
            ];
          } else {
            // If independent news
            newState.path = [
              initPath,
              { name: "News", location: routes.NEWS },
              {
                name: collection[id].title,
                location: `${routes.NEWS}/${id}`,
              },
            ];
          }
        } else {
          // If all news
          newState.path = [initPath, { name: "News", location: routes.NEWS }];
        }
      } else if (postType === "stories") {
        if (collection[id]) {
          if (collection[id].stories_events_id && collection[id].stories_events_id.length) {
            // If story is linked to any event
            const event = collection[id].stories_events_id[0];

            newState.path = [
              initPath,
              {
                name: "Events",
                location: routes.EVENTS,
              },
              {
                name: events[`${event.post_name}-${event.ID}`].title,
                location: `${routes.EVENTS}/${event.post_name}-${event.ID}`,
              },
              {
                name: collection[id].title,
                location: `${routes.STORIES}/${id}`,
                related: true,
              },
            ];
          } else {
            // If independent story
            newState.path = [
              initPath,
              { name: "Stories", location: routes.STORIES },
              {
                name: collection[id].title,
                location: id,
              },
            ];
          }
        } else {
          // If all stories
          newState.path = [initPath, { name: "Stories", location: routes.STORIES }];
        }
      } else if (postType === "events") {
        if (id) {
          newState.path = [
            initPath,
            {
              name: "Events",
              location: routes.EVENTS,
            },
            {
              name: collection.all[id].title,
              location: `${routes.EVENTS}/${id}`,
            },
          ];
          if (teamId) {
            newState.path.push({ name: teams[teamId].team_name, location: teamId });
          }
        } else {
          // If independent story
          newState.path = [initPath, { name: "Events", location: routes.EVENTS }];
        }
       } else if (postType === "boats") {
          if (id) {
            newState.path = [
              initPath,
              {
                name: "Boats",
                location: routes.BOATS,
              },
              {
                name: nextProps.collections.boatModels[id].title,
                location: `${routes.BOATS}/${id}`,
              },
            ];
          } else {
            // If independent story
            newState.path = [initPath, { name: "Boats", location: routes.BOATS }];
          }
        } else if (postType === "owners-clubs") {
           if (id) {
             newState.path = [
               initPath,
               {
                 name: "Owners Club",
                 location: routes.OWNERS_CLUBS,
               },
               {
                 name: nextProps.collections.ownersClubs.countries[id].title,
                 location: `${routes.OWNERS_CLUBS}/${id}`,
               },
             ];
           } else {
             // If independent story
             newState.path = [initPath, { name: "Owners Club", location: routes.OWNERS_CLUBS }];
           }
      } else {
        newState.path = [initPath, { name: postType.replaceAll('-', ' '), location: routes[postType.toUpperCase()] || postType }];
      }
    
      newState.breadcrumbs = newState.path.map((path, index) => {
        if (index === newState.path.length - 1) {
          // Don't print '>' for element in path
          return (
            <Link
              key={index}
              className="breadcrumbsPathName"
              style={{ color: nextProps.color || "#fff" }}
              to={`${path.location}`}
            >
              {path.name}
            </Link>
          );
        }
        return (
          <React.Fragment key={index}>
            <Link
              className="breadcrumbsPathName"
              style={{ color: nextProps.color || "#fff" }}
              to={`${path.location}`}
            >
              {path.name}
            </Link>
            <span className="breadcrumbsPathName" style={{ color: nextProps.color || "#fff" }}>
              &gt;
            </span>
          </React.Fragment>
        );
      });

      newState.url = url;
    }

    return newState;
  }

  render() {
    return (
      <div className="breadcrumbsContainer" style={this.props.style ? this.props.style : null}>
        <div className="container">{this.props.community ? (
          <Link
            className="breadcrumbsPathName"
            style={{ color: "#fff" }}
            to={routes.COMMUNITY}
          >
            Back to Community
          </Link>
        ) : this.state.breadcrumbs}</div>
      </div>
    );
  }
}

export default withRouter(withCollections(Breadcrumbs));
