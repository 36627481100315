import React, { Component } from "react";
import { Link } from "react-router-dom";

import withCollections from "higherOrderComponents/withCollections";
import withTwitterModal from "higherOrderComponents/withTwitterModal";
import api from "../../api/posts";

class LiveTopbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tweets: null,
      liveEvent: null,
    };


    api.pullTwitterFeed();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (!prevState.tweets && nextProps.collections.fetchedCollections.twitter) {
      newState.tweets = Object.values(nextProps.collections.twitter)
        .filter(tweet => tweet.hashtag === nextProps.liveEvent.twitter_tag)
        .slice(0, 12);
    }

    if (!prevState.liveEvent && nextProps.collections.fetchedCollections.events) {
      newState.liveEvent = nextProps.liveEvent;
    }

    return newState;
  }

  render() {
    const twitterUrl = (user_name, post_id) => `https://twitter.com/${user_name}/status/${post_id}`;

    return (
      <div id="liveTopbar">
        <div className="scroll">
          <div className="liveTopbarBtn">
            <Link to={"/events/" + (this.state.liveEvent.id || "")}>
              <div className="liveTopbarBtnContainer">
                <strong>Live now: </strong>
                <span>{this.state.liveEvent.title}</span>
              </div>
            </Link>
          </div>
          {this.state.tweets &&
            this.state.tweets.map(tweet => (
              <TwitterPost
                author={tweet.user_name}
                key={tweet.id}
                content={tweet.post_content}
                setTwitterUrl={this.props.setTwitterUrl}
                url={twitterUrl(tweet.user_screen_name, tweet.id)}
              />
            ))}
        </div>
      </div>
    );
  }
}

const TwitterPost = ({ author, content, setTwitterUrl, url }) => (
  <div className="twitterFeedCard row no-gutters" onClick={() => setTwitterUrl(url)}>
    <div className="twitterFeedCardContainer">
      <div className="col-1">
        <i className="fab fa-twitter fa-xs" />
      </div>
      <div className="col-11">
        <div className="twitterFeedAuthor">
          <span>{author}</span>
        </div>
        <div className="twitterFeedContent">
          <span>{content.slice(0, 33) + "..."}</span>
        </div>
      </div>
    </div>
  </div>
);

export default withTwitterModal(withCollections(LiveTopbar));
