import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class CommunityHero extends Component {
  componentDidMount() {
    if (!this.props.location.hash) {
      window.scrollTo(0, 0);
    }
  }


  render() {
    if (!this.props.collections.fetchedCollections.community) {
      return null;
    }

    return (
      <div
        className="communityHero"
        style={
          this.props.collections.community.featured_photo && {
            background: `url(${this.props.collections.community.featured_photo.large})`,
          }
        }
      >
        <div className="communitysHeroOverlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="communityHeroContainer">
                <h1 className="communityTitle">{this.props.collections.community.title}</h1>
                <p className="communitySubtitle">{this.props.collections.community.intro}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withCollections(CommunityHero));
