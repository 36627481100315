import React, { Component } from "react";

const instagramBackground = {
  background:
    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
};

class CommunityCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedNews: 5,
    };
  }

  render() {
    const {community} = this.props;

    return (
      <React.Fragment>
        <div className="offset-1" />
        <div className="col-10">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <a
                href="https://forum.seascape-edition.com"
                target="_blank"
                className="communityCardLink"
                rel="noopener noreferrer"
              >
                <div className="communityCardContainer">
                  <div className="upperHalf">
                    <div className="communityCardIcon">
                      <img
                        alt="first"
                        src="favicon.ico"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                  </div>
                  <div className="bottomHalf">
                    <div className="bottomHalfContent">
                      <h4>{community.forum_card_title}</h4>
                      <p>{community.forum_card_text}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-12">
              <a
                href="https://www.facebook.com/SeascapeEdition/"
                target="_blank"
                rel="noopener noreferrer"
                className="communityCardLink"
              >
                <div className="communityCardContainer">
                  <div className="upperHalf">
                    <div className="communityCardIcon" style={{ background: "#4e69a2" }}>
                      <i className="fab fa-facebook-f" />
                    </div>
                  </div>
                  <div className="bottomHalf">
                    <div className="bottomHalfContent">
                      <h4>{community.facebook_card_title}</h4>
                      <p>{community.facebook_card_text}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-12">
              <a
                href="https://www.youtube.com/c/SeascapeEdition"
                target="_blank"
                rel="noopener noreferrer"
                className="communityCardLink"
              >
                <div className="communityCardContainer">
                  <div className="upperHalf">
                    <div className="communityCardIcon" style={{ background: "#ed1c24" }}>
                      <i className="fab fa-youtube" />
                    </div>
                  </div>
                  <div className="bottomHalf">
                    <div className="bottomHalfContent">
                      <h4>{community.youtube_card_title}</h4>
                      <p>{community.youtube_card_text}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-1" />
        <div className="offset-1" />
        <div className="col-10">
          <div className="row">
            <div className="offset-lg-2" />
            <div className="col-lg-4 col-md-12">
            <a
                href="https://twitter.com/SeascapeEdition"
                target="_blank"
                className="communityCardLink"
                rel="noopener noreferrer"
              >
                <div className="communityCardContainer">
                  <div className="upperHalf">
                    <div className="communityCardIcon" style={{ background: "#00acee" }}>
                      <i className="fab fa-twitter" />
                    </div>
                  </div>
                  <div className="bottomHalf">
                    <div className="bottomHalfContent">
                      <h4>{community.twitter_card_title}</h4>
                      <p>{community.twitter_card_text}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-12">
              <a
                href="https://www.instagram.com/seascapeedition/"
                target="_blank"
                rel="noopener noreferrer"
                className="communityCardLink"
              >
                <div className="communityCardContainer">
                  <div className="upperHalf">
                    <div className="communityCardIcon" style={instagramBackground}>
                      <i className="fab fa-instagram" />
                    </div>
                  </div>
                  <div className="bottomHalf">
                    <div className="bottomHalfContent">
                      <h4>{community.instagram_card_title}</h4>
                      <p>{community.instagram_card_text}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CommunityCard;
