import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import date from "utils/date";

class EventNewsCard extends Component {
  render() {
    const { news } = this.props;
    const publishDate = date.getPublishDate(news.post_date);

    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);

    return (
      <Link to={"/news/" + news.id}>
        <div id="eventNewsCard">
          <div className="cardOverlay" />
          <div
            className="eventNewsCardTop"
            style={{ background: `url(${
              news.thumbnail_photo && news.thumbnail_photo.thumbnail ? news.thumbnail_photo.thumbnail : news.featured_photo.thumbnail
            })` }}
          />
          <div className="eventNewsCardBottom">
            <div className="eventNewsCardBottomIntro">
              <h4>NEWS</h4>
              <span>Published {editedPublishDate}</span>
              <h3>{news.title}</h3>
            </div>
            <span className="eventNewsCardIntro">
              {news.intro && news.intro.length > 120
                ? news.intro.slice(0, 117) + "..."
                : news.intro}
            </span>
          </div>
        </div>
      </Link>
    );
  }
}

export default withRouter(EventNewsCard);
