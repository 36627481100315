import React, { Component } from "react";

import withCollections from "higherOrderComponents/withCollections";

import EventTeamCard from "../Cards/EventTeamCard";

class EventTeams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadMore: false,
    };
  }

  render() {
    const { event } = this.props;
    const teams = Object.values(this.props.collections.teams).filter(
      team => team.form_id === event.form_id
    );

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="teamsContainer">
              <h2>Teams</h2>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {teams.length >= 1 ? (
                teams.map((team, index) => {
                  if (this.state.loadMore === false && index < 4) {
                    return (
                      <div className="col-xl-3 col-md-6" key={index}>
                        <EventTeamCard team={team} index={index} />
                      </div>
                    );
                  } else if (this.state.loadMore === true) {
                    return (
                      <div className="col-xl-3 col-md-6" key={index}>
                        <EventTeamCard team={team} index={index} />
                      </div>
                    );
                  }
                  return null;
                })
              ) : event.applications_open ? (
                <span className="closedApplicationsMsg">No teams applied yet</span>
              ) : (
                <span className="closedApplicationsMsg">Applications are not open yet</span>
              )}
            </div>
          </div>
        </div>

        {teams.length > 4 ? (
          <div className="row">
            <div className="col-12">
              <div className="loadMoreTeamsContainer">
                {this.state.loadMore ? (
                  <button
                    className="load-more-button"
                    onClick={() => {
                      this.setState({ loadMore: false });
                    }}
                  >
                    Show less teams
                  </button>
                ) : (
                  <button
                    className="load-more-button"
                    onClick={() => {
                      this.setState({ loadMore: true });
                    }}
                  >
                    Show more teams
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withCollections(EventTeams);
