import React, { Component } from "react";

import Axios from "axios";
import withCollections from "higherOrderComponents/withCollections";

class SubscribeSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      status: "",
      message: "",
      title: "",
      sending: false,
    };
  }

  subscribe = () => {
    this.setState({ sending: true });
    const formData = new FormData();
    formData.set("email", this.state.email);

    return Axios({
      method: "POST",
      url: "https://seascape-edition.com/seascape/wp-json/post/subscribe",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(({ data }) => {
        if (data.subscribed) {
          this.setState({
            status: "success",
            title: "Success",
            message: "You have been successfully subscribed!",
            email: "",
          });
          setTimeout(
            () => this.setState({ status: "", message: "", title: "" }),
            3500
          );
        } else {
          const errorMessage =
            data.message === "Member Exists"
              ? "You are already subscribed"
              : data.message;
          this.setState({
            status: "danger",
            message: errorMessage,
            title: "Failed",
          });
          setTimeout(
            () => this.setState({ status: "", message: "", title: "" }),
            3500
          );
        }
      })
      .catch(() => {
        this.setState({
          status: "danger",
          message: "Error while trying to subscribe!",
          title: "Failed",
        });
        setTimeout(
          () => this.setState({ status: "", message: "", title: "" }),
          3500
        );
      })
      .then(() => this.setState({ sending: false }));
  };

  render() {
    return (
      <div id="subscribe">
        <div className="container">
          {this.state.status && (
            <div className={`alert alert-${this.state.status} subscribeAlert`}>
              <strong>{this.state.title}!</strong> {this.state.message}
            </div>
          )}
          <div className="col-12 subscribeContainerCenter">
            <div className="subscribeContainer">
              <h2>{this.props.collections.subscribe.title}</h2>
              <p>{this.props.collections.subscribe.subtitle}</p>
              <div className="subscribeInputContainer">
                <input
                  className="subscribeInput"
                  placeholder="Enter your e-mail"
                  value={this.state.email}
                  onChange={({ target }) =>
                    this.setState({ email: target.value })
                  }
                />
                <button
                  className={`subscribeSubmitButton${
                    this.state.status ? "Disabled" : ""
                  }`}
                  onClick={this.subscribe}
                  disabled={!!this.state.status}
                >
                  SIGN UP{" "}
                  {this.state.sending && (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCollections(SubscribeSection);
