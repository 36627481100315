import React, { Component } from "react";

import NewsCard from "../Cards/NewsCard";
import withCollections from "higherOrderComponents/withCollections";

class CommunityNews extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="offset-xl-1"/>
        {
          this.props.collections.fetchedCollections.community && this.props.collections.community.cards ? Object.values(this.props.collections.community.cards).map((card, index) => 
            <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3 mb-3">
              <NewsCard news={card} hideDate link={card.link} useLink external={card.link.startsWith('https://')}/>
            </div>
          )
          : null
        }
      </React.Fragment>
    );
  }
}

export default withCollections(CommunityNews);