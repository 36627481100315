import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Checkbox from "./checkbox";
import Filedrop from "./filedrop";
import { Helmet } from "react-helmet";
import Input from "./input";
import Multiselect from "./multiselect";
import NotFound from "../NotFound";
import ReCAPTCHA from "react-google-recaptcha";
import RegisterFormHero from "../../components/Heros/RegisterFormHero";
import axios from "axios";
import routes from "constants/routes";
import withCollections from "higherOrderComponents/withCollections";

function RegisterForm(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [requiredFields, setRequiredField] = useState({});
  const [errorFields, setErrorField] = useState({});
  const [formLayout, setFormLayout] = useState({});
  const [formData, setFormData] = useState({
    event_id: props.match.params.id.split("-").pop(),
  });
  const [formGtoken, setFormGtoken] = useState({});
  const [formId, setFormId] = useState(null);
  const [successfullyRegistered, setRegisterState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  let recaptchaRef = null;

  useEffect(() => {
    if (formId) {
      axios
        .get(`https://seascape-edition.com/seascape/wp-json/get/form/${formId}`)
        .then((resp) => {
          setFormLayout(resp.data);
          setFormData({ ...formData, form_id: resp.data.id });
        });
    }
  }, [formId]);

  const event =
    props.collections.fetchedCollections.events &&
    props.collections.events.all[props.match.params.id];

  if (!event || !event.form_id) {
    return <NotFound />;
  } else if (!formId && event.form_id) {
    setFormId(event.form_id);
  }

  if (
    !event.applications_open ||
    !(
      props.collections.events.upcoming[props.match.params.id] ||
      props.collections.events.live[props.match.params.id]
    )
  ) {
    return (
      <React.Fragment>
        <Helmet>
          <title> Seascape Edition | Register Form</title>
        </Helmet>
        <h2 className="registerFormMessage">
          This event is currently not accepting registrations.
        </h2>
      </React.Fragment>
    );
  }

  return (
    <div id="registerForm">
      <Helmet>
        <title> Seascape Edition | Register Form</title>
        <meta
          name="description"
          content="We are here to inspire people to become sailors. More precisely, to expand their comfort zone by connecting to the wind, the sea and fellow sailors."
        />
      </Helmet>
      <div
        className={`alert alert-danger registerFormAlert${
          errorMessage ? "Active" : ""
        }`}
        role="alert"
      >
        {errorMessage}
      </div>
      <section>
        <RegisterFormHero event={event} />
      </section>
      {!successfullyRegistered ? (
        <section>
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 ">
                <div className="registerFormContainer">
                  <h2>{event.title}</h2>
                  <form name={formLayout.title}>
                    {formLayout.fields &&
                      formLayout.fields.map(
                        (field) =>
                          formLayout.fields && (
                            <FormBuilder
                              key={field.id}
                              field={field}
                              data={formData}
                              setForm={setFormData}
                              setRequiredField={setRequiredField}
                              requiredFields={requiredFields}
                              errorFields={errorFields}
                            />
                          )
                      )}
                    <div className="registerFormCheckbox">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            newsletter_subscription: e.target.checked,
                          })
                        }
                      />
                      <span>
                        I want to sign up to News &amp; Marketing activities
                        about First
                      </span>
                    </div>
                    <div className="registerFormCheckbox">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDisabled(false);
                          } else {
                            setDisabled(true);
                          }
                        }}
                      />
                      <span>
                        I Agree with Terms &amp; Conditions of this form
                      </span>
                    </div>
                    <ReCAPTCHA
                      ref={(ref) => (recaptchaRef = ref)}
                      className="registerFormRecaptcha"
                      onChange={(gToken) => {
                        setFormGtoken({ gToken });
                      }}
                      onExpired={() => recaptchaRef.reset()}
                      sitekey="6Ld1v7IUAAAAAJ3VRQoKbOZXuy-EvkVoHCjRWfM0"
                    />
                    <button
                      disabled={disabled || loading}
                      className={`registerFormButtonSubmit ${
                        disabled ? "darken" : ""
                      }`}
                      onClick={(e) => {
                        setLoading(true);
                        submitForm(
                          e,
                          formLayout,
                          formData,
                          formGtoken,
                          formId,
                          requiredFields,
                          setErrorField,
                          () => {
                            setRegisterState(true);
                            setLoading(false);
                            recaptchaRef && recaptchaRef.reset();
                          },
                          (error) => {
                            setLoading(false);
                            setErrorMessage(
                              error.customMessage ||
                                error.response.data.message ||
                                error.message
                            );
                            recaptchaRef && recaptchaRef.reset();
                            setTimeout(() => setErrorMessage(""), 3500);
                          }
                        );
                      }}
                    >
                      {loading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        "Sign up NOW"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="registerFormMessage text-center">
                <h2>You have successfully submitted your application.</h2>
                <p>Check your email for further information.</p>
                <p>
                  Once confirmed, your team will be listed on the{" "}
                  <Link to={`${routes.EVENTS}/${props.match.params.id}`}>
                    event page
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function submitForm(
  e,
  form,
  data,
  formRecaptcha,
  formId,
  requiredFields,
  setErrorField,
  onSuccess,
  onError
) {
  e.preventDefault();
  let formData = new FormData();

  let missingFields = {};
  Object.keys(requiredFields).forEach((field) => {
    if (!data.hasOwnProperty(field) || !data[field]) {
      missingFields[field] = true;
    }
  });

  if (Object.keys(missingFields).length !== 0) {
    onError({ customMessage: "Missing input field values!" });
    return setErrorField({ ...missingFields });
  }

  for (let key of Object.keys(data)) {
    formData.set(key, data[key]);
  }

  formData.set(
    "fileIds",
    JSON.stringify({
      fileIds: Object.keys(data).filter(
        (fieldName) => data[fieldName] instanceof File
      ),
    })
  );

  formData.set("gToken", formRecaptcha.gToken);
  formData.set("form_id", formId);

  return axios({
    url: `https://seascape-edition.com/seascape/wp-json/post/form/${form.id}`,
    method: "POST",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(onSuccess)
    .catch(onError);
}

function FormBuilder({
  field,
  data,
  setForm,
  setRequiredField,
  requiredFields,
  errorFields,
}) {
  switch (field.type) {
    case "text":
    case "email":
    case "number":
      if (field.isRequired && !requiredFields.hasOwnProperty(field.id)) {
        setRequiredField({ ...requiredFields, [field.id]: true });
      }
      return (
        <Input
          errorMessage={errorFields[field.id]}
          field={field}
          data={data}
          onChange={setForm}
        />
      );
    case "checkbox":
      if (field.isRequired && !requiredFields.hasOwnProperty(field.id)) {
        setRequiredField({ ...requiredFields, [field.id]: true });
      }
      return (
        <div className="registerFormCheckboxContainer">
          <span className="registerFormCheckboxLabel">{field.label}</span>
          <Checkbox
            errorMessage={errorFields[field.id]}
            field={field}
            data={data}
            onChange={setForm}
          />
        </div>
      );
    case "multiselect":
      if (field.isRequired && !requiredFields.hasOwnProperty(field.id)) {
        setRequiredField({ ...requiredFields, [field.id]: true });
      }
      return (
        <Multiselect
          errorMessage={errorFields[field.id]}
          field={field}
          data={data}
          onChange={setForm}
        />
      );
    case "fileupload":
      if (field.isRequired && !requiredFields.hasOwnProperty(field.id)) {
        setRequiredField({ ...requiredFields, [field.id]: true });
      }
      return (
        <Filedrop
          errorMessage={errorFields[field.id]}
          field={field}
          data={data}
          onDrop={setForm}
        />
      );

    default:
      return null;
  }
}

export default withRouter(withCollections(RegisterForm));
