const postTypes = {
  ABOUT: "about",
  BOAT_MODELS: "boatModels",
  BOATS: "boats",
  COMMUNITY: "community",
  EVENTS: "events",
  KNOWLEDGE_BASE: "knowledgeBase",
  LANDING: "landing",
  LEGAL: "legal",
  MASONRY: "masonry",
  OWNERS_CLUBS: "ownersClubs",
  NEWS: "news",
  POPUP: "popup",
  PROJECTS: "projects",
  STORIES: "stories",
  SUBSCRIBE: "subscribe",
  TAGS: "tags",
  TEAMS: "teams",
  THEME_SETTINGS: "theme",
  TWITTER: "twitter",
  VLOG: "vlog",
  WELCOME_ABOARD: "welcomeAboard",
};

export default postTypes;
