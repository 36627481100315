import React, { Component } from "react";

import NewsCard from "../Cards/NewsCard";
import routes from "constants/routes";

class OwnersClubNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedNews: 10,
    };
  }
  render() {
    const {ownersClubs} = this.props;

    return (
        <React.Fragment>
            {ownersClubs && Object.keys(ownersClubs.countries).slice(0, this.state.loadedNews).map((postId, index) => {
                const post = ownersClubs.countries[postId];
                post.id = postId;
                return (
                    <React.Fragment>
                        {index !== 0 && index % 5 === 0 ? <div className="offset-xl-1" /> : null}
                        {index % 5 === 0  ? <div className="offset-xl-1" /> : null}
                        <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3 mb-3">
                            <NewsCard hideDate useLink link={`${routes.OWNERS_CLUBS}/${postId}`} news={post}/>
                        </div>
                    </React.Fragment>
                )
            })}
            {Object.keys(ownersClubs && ownersClubs.countries).length > this.state.loadedNews ? (
                <div className="row">
                    <div className="col-12">
                        <div className="loadMoreButtonContainer">
                            <button
                            className="load-more-button"
                            onClick={() => {
                                this.setState({ loadedNews: this.state.loadedNews + 5 });
                            }}
                            >
                            Load more news
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
        );
    }
}

export default OwnersClubNews;
