import React, { Component } from "react";

import AboutCard from "components/Cards/AboutCard";
import Breadcrumbs from "components/Breadcrumbs";
import { Helmet } from "react-helmet";
import HorizontalTimeline from "react-horizontal-timeline";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
    api.pullAbout();
    this.state = {
      value: 0,
      previous: 0,
      person: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  gallery() {
    const { person } = this.state;

    if (person) {
      return (
        <div className="galleryContainer">
          <div
            className="galleryOverlay"
            onClick={() => {
              this.setState({ person: null });
            }}
          />
          <div className="imageContainer">
            <img
              src={
                (person.featured_photo && person.featured_photo.large) ||
                person.photo.large
              }
              alt={person.name}
            />
          </div>
          <div className="closeButton">
            <i
              className="fas fa-times fa-2x"
              onClick={() => {
                this.setState({ person: null });
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  openGallery = (person) => {
    this.setState({ person });
  };

  render() {
    return (
      <div id="about">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Seascape Edition | About</title>
          <meta
            name="description"
            content="We are here to inspire people to become sailors. More precisely, to expand their comfort zone by connecting to the wind, the sea and fellow sailors."
          />
        </Helmet>
        {this.gallery()}
        <section>
          <div
            className="aboutHero"
            style={
              this.props.collections.about.featured_photo && {
                background: `url(${this.props.collections.about.featured_photo.original})`,
              }
            }
          >
            <div className="heroOverlay" />
            <div className="heroContentContainer">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-mdlg-8 col-lg-8 col-xl-6">
                    <h1>
                      {this.props.collections.fetchedCollections.about &&
                        this.props.collections.about.new_title}
                    </h1>
                    <p>
                      {this.props.collections.fetchedCollections.about &&
                        this.props.collections.about.intro}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Breadcrumbs />
          </div>
        </section>
        <section id="navbarTrigger">
          <div className="container">
            <hr />
            <h2 className="aboutTitle">Team</h2>
            <h2 className="aboutTitle">Management</h2>
            <div className="row">
              {this.props.collections.fetchedCollections.about &&
                this.props.collections.about.management &&
                this.props.collections.about.management.map((person, index) => (
                  <AboutCard
                    key={index}
                    {...person}
                    onClick={this.openGallery}
                  />
                ))}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h2 className="aboutTitle">Production</h2>
            <div className="row">
              {this.props.collections.fetchedCollections.about &&
                this.props.collections.about.production &&
                this.props.collections.about.production.map((person, index) => (
                  <AboutCard
                    key={index}
                    {...person}
                    onClick={this.openGallery}
                  />
                ))}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h2 className="aboutTitle">Support</h2>
            <div className="row">
              {this.props.collections.fetchedCollections.about &&
                this.props.collections.about.support &&
                this.props.collections.about.support.map((person, index) => (
                  <AboutCard
                    key={index}
                    {...person}
                    onClick={this.openGallery}
                  />
                ))}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h2 className="aboutTitle">Research and development</h2>
            <div className="row">
              {this.props.collections.fetchedCollections.about &&
                this.props.collections.about.research_development &&
                this.props.collections.about.research_development.map(
                  (person, index) => (
                    <AboutCard
                      key={index}
                      {...person}
                      onClick={this.openGallery}
                    />
                  )
                )}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h2 className="aboutTitle">Communication &amp; Sales</h2>
            <div className="row">
              {this.props.collections.fetchedCollections.about &&
                this.props.collections.about.communications_sales &&
                this.props.collections.about.communications_sales.map(
                  (person, index) => (
                    <AboutCard
                      key={index}
                      {...person}
                      onClick={this.openGallery}
                    />
                  )
                )}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <hr />
            <h2 className="aboutTitle">Technology</h2>
            <div className="aboutVideoContainer">
              {this.props.collections.fetchedCollections.about && (
                <iframe
                  title="video"
                  className="player"
                  width="100%"
                  height="100%"
                  src={
                    "https://www.youtube.com/embed/" +
                    this.props.collections.about.video
                  }
                  frameBorder="0"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        </section>
        <section className="aboutHistorySection">
          <div className="container">
            <hr />
            <h2 className="aboutTitle aboutTitleHistory">History</h2>
          </div>
          <div className="aboutHistory">
            {this.props.collections.fetchedCollections.about && (
              <HorizontalTimeline
                index={this.state.value}
                indexClick={(index) => {
                  this.setState({ value: index, previous: this.state.value });
                }}
                values={
                  this.props.collections.fetchedCollections.about &&
                  this.props.collections.about.history.map(
                    (event) => `${event.year}/01/01`
                  )
                }
                getLabel={(date) => date.split("/")[0]}
                styles={{
                  foreground: "#ed1c24",
                  background: "#ed1c24",
                  outline: "#fff",
                }}
              />
            )}
          </div>
          <div className="aboutHistory">
            {this.props.collections.fetchedCollections.about && (
              <div className="aboutContentHistory">
                <h2>
                  {this.props.collections.about.history[this.state.value].year}
                </h2>
                <p className="aboutContent">
                  {
                    this.props.collections.about.history[this.state.value]
                      .content
                  }
                </p>
              </div>
            )}
          </div>
        </section>
        <section>
          <div
            className="aboutHero"
            style={
              this.props.collections.about.footer_photo && {
                background: `linear-gradient(to top,transparent 75%,#1b232b), linear-gradient(to bottom, transparent 75%,#1b232b),  url(${this.props.collections.about.footer_photo.original})`,
              }
            }
          />
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(About));
