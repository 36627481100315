import React, { Component } from "react";

import Breadcrumbs from "components/Breadcrumbs";
import { Helmet } from "react-helmet";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class WelcomeAboard extends Component {
  constructor(props) {
    super(props);
    api.pullWelcomeAboard();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="legal">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Seascape Edition | Welcome Aboard</title>
          {/* <link rel="canonical" href={routes.URL} /> */}
        </Helmet>
        <section>
          <div
            className="legalHero"
            style={
              this.props.collections.welcomeAboard.featured_photo && {
                background: `url(${this.props.collections.welcomeAboard.featured_photo.original})`,
              }
            }
          >
            <div className="heroOverlay" />
            <Breadcrumbs />
          </div>
        </section>
        <section id="navbarTrigger">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="wordpressContent">
                  <h2 className="legalTitle">
                    {this.props.collections.fetchedCollections.welcomeAboard &&
                      this.props.collections.welcomeAboard.title}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (this.props.collections.fetchedCollections
                          .welcomeAboard &&
                          this.props.collections.welcomeAboard.content) ||
                        null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(WelcomeAboard));
