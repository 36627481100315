import React, { Component } from "react";

import { Helmet } from "react-helmet";
import HorizontalScroll from "components/Scrollers/Horizontal";
import StoriesHero from "components/Heros/StoriesHero";
import StoryCard from "components/Cards/StoryCard";
import SubscribeSection from "components/SubscribeSection";
import withCollections from "higherOrderComponents/withCollections";

class Stories extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const stories =
      this.props.collections.fetchedCollections.stories &&
      Object.keys(this.props.collections.stories).map((storyId) => ({
        ...this.props.collections.stories[storyId],
        id: storyId,
      }));

    return (
      <div className="storiesContainer">
        <Helmet>
          <title> Seascape Edition | Stories</title>
          <meta
            name="description"
            content="We tell the stories of ordinary people doing extraordinary things to inspire others to do the same."
          />
        </Helmet>

        <section>
          <StoriesHero />
        </section>
        <section id="navbarTrigger">
          <div className="container">
            <h2>New Video Stories</h2>
            <StoryScrollSection
              list={stories}
              filterBy={(story) => story.type === "video"}
              index={0}
            />
            <h2>New Photo Stories</h2>
            <StoryScrollSection
              list={stories}
              filterBy={(story) => story.type === "image"}
              index={1}
            />
            <h2>All Time Heroes</h2>
            <StoryScrollSection
              list={stories}
              filterBy={(story) => story.all_time_hero}
              index={2}
            />
          </div>
        </section>
        <SubscribeSection />
      </div>
    );
  }
}

const StoryScrollSection = ({ list, filterBy, index }) => (
  <HorizontalScroll index={index}>
    {list &&
      list.filter(filterBy).map((story) => (
        <div className="storyScrollSectionCard" key={story.id}>
          <StoryCard story={story} />
        </div>
      ))}
  </HorizontalScroll>
);

export default withCollections(Stories);
