import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import _ from "lodash";
import date from "utils/date";
import routes from "constants/routes";

class StoryCard extends Component {
  render() {
    const publishDate = date.getPublishDate(this.props.story.post_date);
    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);
    const link = this.props.story.post_type === "news" ? routes.NEWS : routes.STORIES;

    return (
      <Link to={`${link}/${this.props.story.id}`}>
        <div id="storyCard" className={this.props.isReview ? "reviewCard" : ""}>
          <div
            className="storyContent"
            style={{
              backgroundImage: `url(${
                this.props.story.thumbnail_photo && this.props.story.thumbnail_photo.thumbnail ? this.props.story.thumbnail_photo.thumbnail : this.props.story.featured_photo.thumbnail
              })`,
            }}
          />
          {!this.props.isReview && this.props.story.type === "video" && 
            <div className="storyCardIcon">
              <i className="fas fa-play fa-3x" />
            </div>
          }
          {!this.props.isReview && this.props.story.type === "image" && 
            <div className="storyCardIcon">
              <i className="fas fa-camera fa-3x" />
            </div>
          }
          <div className="storyCardTitle">
            <h4>{_.capitalize(this.props.story.post_type)}</h4>
            <h3>{this.props.story.title}</h3>
          </div>
          {!this.props.isReview ? <div className="storyCardPublishDate">Published {editedPublishDate}</div> : null}
        </div>
      </Link>
    );
  }
}

export default withRouter(StoryCard);
