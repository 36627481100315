import "reactjs-popup/dist/index.css";

import React, { Component } from "react";

import Carousel from "components/Carousel";
import { Helmet } from "react-helmet";
import Masonry from "components/Masonry";
import OwnersGrid from "components/OwnersGrid";
import Popup from "reactjs-popup";
import SocialSidebar from "components/SocialSidebar";
import SubscribeSection from "components/SubscribeSection";
import withCollections from "higherOrderComponents/withCollections";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      landing: null,
      masonry: null,
      owners: null,
      posts: null,
      popupDisplayed: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (
      nextProps.collections.fetchedCollections.landing &&
      nextProps.collections.fetchedCollections.masonry &&
      nextProps.collections.fetchedCollections.theme
    ) {
      newState.landing = nextProps.collections.landing;
      newState.masonry = nextProps.collections.masonry;
      newState.owners = nextProps.collections.theme.owners;
    }

    if (nextProps.collections.fetchedCollections.landing && !prevState.posts) {
      const allPosts = nextProps.collections.landing;
      newState.posts = Object.keys(allPosts)
        .map((postId) => {
          allPosts[postId].id = postId;
          return allPosts[postId];
        })
        .slice(0, 3);
    }

    return newState;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!localStorage.getItem("popupDisplayed")) {
      this.setState({ popupDisplayed: false });
    }
  }

  popupDisplayed() {
    localStorage.setItem("popupDisplayed", true);
  }

  notificationSubscription() {
    setTimeout(() => window.OneSignal.showNativePrompt(), 500);
  }

  gotoNewsletter() {
    this.setState({ popupDisplayed: true });
    window.$([document.documentElement, document.body]).animate(
      {
        scrollTop: window.$("#subscribe").offset().top,
      },
      1000
    );
  }

  render() {
    return (
      <div id="home">
        <Helmet>
          <title> Seascape Edition | Connect with the elements</title>
          <meta
            name="description"
            content="At Seascape, we build communities, knowledge, and fast, responsive sailing boats in order to empower anyone to become a Sailor. We work through two brands: the iconic “Beneteau First” and the innovative “SE – Seascape Edition” on a mission to achieve the ultimate connection between humans and the sea."
          />
        </Helmet>

        <Popup
          modal
          open={
            this.props.collections.fetchedCollections.popup &&
            !this.props.loading &&
            !this.state.popupDisplayed
          }
          onOpen={() => this.popupDisplayed()}
        >
          <div
            className="popupImage"
            style={{
              backgroundImage: `url(${
                this.props.collections.fetchedCollections.popup &&
                this.props.collections.popup.featured_photo.large
              })`,
            }}
          />
          <i
            onClick={() => this.setState({ popupDisplayed: true })}
            className="fas fa-times fa-2x closeIcon"
          ></i>
          <div id="wordpressContent" className="popupText">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  (this.props.collections.fetchedCollections.popup &&
                    this.props.collections.popup.content) ||
                  null,
              }}
            />
          </div>
          {/* <div className="row no-gutters buttonsRow">
            <button
              className="button-primary popupButton"
              name="notification"
              onClick={() => this.notificationSubscription()}
            >
              TURN ON THE NOTIFICATIONS
            </button>
            <button
              className="button-primary popupButton"
              onClick={() => this.gotoNewsletter()}
            >
              SIGN UP TO NEWSLETTER
            </button>
          </div> */}
        </Popup>

        <SocialSidebar />
        <Carousel posts={this.state.posts} />
        <section className="masonryContainer" id="navbarTrigger">
          <Masonry posts={this.state.posts} masonry={this.state.masonry} />
        </section>

        <OwnersGrid content={this.state.owners} />

        <SubscribeSection />
      </div>
    );
  }
}

export default withCollections(Home);
