import React, { Component } from "react";

import BoatFeatures from "../../components/BoatFeatures";
import BoatMenu from "../../components/BoatMenu";
// import BoatSpecs from "../../components/BoatSpecs";
import { Helmet } from "react-helmet";
import HorizontalScroll from "components/Scrollers/Horizontal";
import NotFound from "../NotFound";
import SingleBoatHero from "../../components/Heros/SingleBoatHero";
import StoryCard from "components/Cards/StoryCard";
import WideCardEvents from "../../components/Cards/WideCardEvents";
import YoutubeCard from "components/Cards/YoutubeCard";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class SingleBoat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boatId: null,
      activeMenuLink: "",
      currentBoat: null,
    };

    api.pullBoatModels();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (nextProps.match.params.id !== prevState.boatId) {
      window.scrollTo(0, 0);
      newState.boatId = nextProps.match.params.id;
    }

    if (
      nextProps.collections.fetchedCollections.news &&
      nextProps.collections.fetchedCollections.stories &&
      nextProps.collections.fetchedCollections.boatModels &&
      !prevState.currentBoat
    ) {
      newState.currentBoat =
        nextProps.collections.boatModels[nextProps.match.params.id];
      newState.relatedStoryNews = newState.currentBoat.related_stories_news.map(
        (postId) => {
          const post =
            nextProps.collections.stories[postId] ||
            nextProps.collections.news[postId];
          if (!post) {
            return null;
          }
          post.id = postId;
          return post;
        }
      );
    }

    return newState;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var element = document.getElementById("navbarFixed");
    element.classList.remove("navbarFixedVisible");

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let activeSection = document.getElementsByTagName("section");

    for (var element of activeSection) {
      if (
        window.pageYOffset >= element.offsetTop - 150 &&
        window.pageYOffset <= element.offsetTop + element.clientHeight
      ) {
        this.setState({ activeMenuLink: element.id });
      }
    }
  };

  render() {
    const { currentBoat } = this.state;

    if (!currentBoat) {
      return <NotFound />;
    }

    return (
      <div id="singleBoat">
        <Helmet>
          <title> Seascape Edition | {currentBoat.title}</title>
          <meta name="description" content={currentBoat.intro} />
        </Helmet>

        <section>
          <SingleBoatHero boat={currentBoat} />
        </section>

        <section className="eventMenuSection" id="BoatsMenu">
          <BoatMenu active={this.state.activeMenuLink} />
        </section>

        <section id="Features">
          <BoatFeatures active={this.state.activeMenuLink} boat={currentBoat} />
        </section>

        {/*<section id="Specs" className="mt-8">*/}
        {/*  <BoatSpecs*/}
        {/*    active={this.state.activeMenuLink}*/}
        {/*    boatId={this.state.boatId}*/}
        {/*    content={currentBoat.boat_spec_content}*/}
        {/*  />*/}
        {/*</section>*/}

        <section id="StoriesNews" className="mt-8">
          <div className="container">
            <div className="comunityContentHeaderContainer">
              <h2>{currentBoat.title} Stories&amp;News</h2>
            </div>
            <StoriesNewsScrollSection
              list={this.state.relatedStoryNews}
              index={0}
            />
          </div>
        </section>

        <section id="OwnerReviews" className="mt-8">
          <div className="container">
            <div className="comunityContentHeaderContainer">
              <h2>Owner reviews</h2>
            </div>
            <ReviewsScrollSection
              list={
                this.props.collections.fetchedCollections.news &&
                this.props.collections.fetchedCollections.stories
                  ? [
                      ...Object.keys(this.props.collections.news),
                      ...Object.keys(this.props.collections.stories),
                    ]
                      .filter((postId) =>
                        (
                          this.props.collections.stories[postId] ||
                          this.props.collections.news[postId]
                        ).tag.includes(currentBoat.owner_reviews)
                      )
                      .map((postId) => {
                        const post =
                          this.props.collections.stories[postId] ||
                          this.props.collections.news[postId];
                        post.id = postId;
                        return post;
                      })
                  : []
              }
              index={1}
            />
          </div>
        </section>

        <section id="Events" className="mt-8">
          <div className="container">
            <div className="comunityContentHeaderContainer">
              <h2>{currentBoat.title} Events</h2>
            </div>
            {this.props.collections.fetchedCollections.events &&
            currentBoat.related_events.length ? (
              currentBoat.related_events
                .map((eventId) => {
                  const event = this.props.collections.events.all[eventId];
                  event.id = eventId;
                  return event;
                })
                .map((event, index) => (
                  <WideCardEvents loading={true} event={event} key={index} />
                ))
            ) : (
              <div className="comunityContentHeaderContainer">
                <p className="text-center">No related events</p>
              </div>
            )}
          </div>
        </section>

        <section id="Videos" className="mt-8">
          <div className="container">
            <div className="comunityContentHeaderContainer">
              <h2>{currentBoat.title} Videos</h2>
            </div>
            <VideosScrollSection list={currentBoat.videos} index={2} />
          </div>
        </section>

        <section className="mb-8">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="comunityContentHeaderContainer communityContentCards">
                  <h2>
                    Learn more at{" "}
                    <a href={`${currentBoat.link}`} className="underlined-link">
                      beneteau.com
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const StoriesNewsScrollSection = ({ list, index }) => {
  if (!list.length) {
    return (
      <div className="comunityContentHeaderContainer">
        <p className="text-center">No related stories and news</p>
      </div>
    );
  }
  return (
    <HorizontalScroll index={index}>
      {list &&
        list.map(
          (post) =>
            post && (
              <div className="storyScrollSectionCard" key={post.id}>
                <StoryCard story={post} />
              </div>
            )
        )}
    </HorizontalScroll>
  );
};

const ReviewsScrollSection = ({ list, index }) => {
  if (!list.length) {
    return (
      <div className="comunityContentHeaderContainer">
        <p className="text-center">No related reviews</p>
      </div>
    );
  }
  return (
    <HorizontalScroll index={index}>
      {list &&
        list.map((post) => (
          <div className="storyScrollSectionCard" key={post.id}>
            <StoryCard isReview story={post} />
          </div>
        ))}
    </HorizontalScroll>
  );
};

const VideosScrollSection = ({ list, index }) => {
  if (!list.length) {
    return (
      <div className="comunityContentHeaderContainer">
        <p className="text-center">No related videos</p>
      </div>
    );
  }
  return (
    <HorizontalScroll index={index}>
      {list &&
        list.map((post) => (
          <div className="storyScrollSectionCard" key={post.id}>
            <YoutubeCard video={post} />
          </div>
        ))}
    </HorizontalScroll>
  );
};

export default withRouter(withCollections(SingleBoat));
