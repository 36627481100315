import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Masonry from "react-masonry-component";

import date from "utils/date";

import StoryCard from "components/Cards/StoryCard";
import EventCard from "components/Cards/EventCard";
import NewsCard from "components/Cards/NewsCard";

class CustomMasonry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: null,
      postIds: null,
      masonryContent: null,
      isMobile: window.innerHeight <= 576,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (!prevState.masonryContent && nextProps.masonry) {
      newState.masonryContent = nextProps.masonry;
    }

    if (nextProps.posts && !prevState.posts) {
      newState.posts = nextProps.posts;

      newState.postIds = newState.posts.map(post => post.id);
    }

    return newState;
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    if (window.innerWidth <= 576) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    return (
      <div id={this.props.id}>
        <div id="masonry" className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="masonryTitle">Discover First</h2>
            </div>
          </div>
          <Masonry>
            {this.state.isMobile &&
              this.state.posts &&
              this.state.masonryContent &&
              this.state.posts.slice(1).map((item, index) => {
                switch (item.post_type) {
                  case "story":
                    return (
                      <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
                        <StoryCard story={item} />
                      </div>
                    );
                  case "event":
                    return (
                      <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
                        <EventCard event={item} />
                      </div>
                    );
                  default:
                    // Fallback to newsCard component
                    return (
                      <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
                        <NewsCard news={item} />
                      </div>
                    );
                }
              })}
            {this.state.masonryContent &&
              this.state.postIds &&
              Object.keys(this.state.masonryContent)
                .sort((a, b) =>
                  date.getDateTime(this.state.masonryContent[a].post_date) >
                  date.getDateTime(this.state.masonryContent[b].post_date)
                    ? -1
                    : 1
                )
                .filter(itemId => !this.state.postIds.includes(itemId) && !this.state.masonryContent[itemId].community)
                .slice(0, 9)
                .map((itemId, index) => {
                  const item = this.state.masonryContent[itemId];
                  item.id = itemId;

                  switch (item.post_type) {
                    case "story":
                      return (
                        <div key={index} className="col-mdlg-4 col-md-6 col-sm-12 mt-3 mb-3">
                          <StoryCard story={item} />
                        </div>
                      );
                    case "event":
                      return (
                        <div key={index} className="col-mdlg-4 col-md-6 col-sm-12 mt-3 mb-3">
                          <EventCard event={item} />
                        </div>
                      );
                    default:
                      // Fallback to newsCard component
                      return (
                        <div key={index} className="col-mdlg-4 col-md-6 col-sm-12 mt-3 mb-3">
                          <NewsCard news={item} />
                        </div>
                      );
                  }
                })}
          </Masonry>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomMasonry);
