import React, { Component } from "react";

import { Link } from "react-router-dom";
import date from "utils/date";
import withCollections from "higherOrderComponents/withCollections";

class WideCardNews extends Component {
  render() {
    const { news } = this.props;

    const publishDate = date.getPublishDate(news.post_date);

    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);

    return (
      <div className="row">
        <Link className="linkNewsCard" to={"/news/" + news.id}>
          <div className="wideCardNewsContainer">
            <div className="row rowContentPosition">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div
                  className="wideCardNewsImg"
                  style={{
                    background: `url(${
                      news.thumbnail_photo && news.thumbnail_photo.large
                        ? news.thumbnail_photo.large
                        : news.featured_photo.large
                    })`,
                  }}
                />
                <button className="imgButton">Read more</button>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="wideCardNewsContent">
                  <div className="col-12">
                    <h3>{news.title}</h3>
                  </div>
                  <div className="col-12 publishDate">
                    <span>Published {editedPublishDate}</span>
                  </div>
                  <div className="col-12">
                    <p>
                      {news.intro && news.intro.length > 190
                        ? news.intro.slice(0, 187) + "..."
                        : news.intro}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default withCollections(WideCardNews);
