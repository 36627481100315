import React, { Component } from "react";

import { Helmet } from "react-helmet";
import VlogContent from "../../components/VlogContent";
import VlogHero from "../../components/Heros/VlogHero";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class Vlog extends Component {
  constructor(props) {
    super(props);
    api.pullVlog();
    api.pullCommunity();
  }
  render() {
    const vlog =
      this.props.collections.fetchedCollections.vlog &&
      this.props.collections.vlog;
    const community =
      this.props.collections.fetchedCollections.community &&
      this.props.collections.community;

    return (
      <div id="community">
        <Helmet>
          <title> Seascape Edition | Vlog</title>
          <meta
            name="description"
            content="The fastest-growing community of recreational sailors connected by the love of the sea, wind, challenging adventures and camaraderie."
          />
        </Helmet>
        <section>
          <VlogHero />
        </section>
        <section id="navbarTrigger">
          <VlogContent vlog={vlog} community={community} />
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(Vlog));
