import React from "react";

function Checkbox({ field, data, onChange, errorMessage }) {
  return field.choices.map((choice, index) => (
    <React.Fragment>
      <div
        key={field.inputs[index].id}
        className={`registerFormCheckbox${errorMessage ? " error" : ""}`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <input
          name={field.inputs[index].id}
          onChange={e => {
            if (e.target.checked) {
              onChange({
                ...data,
                [field.inputs[index].id]: choice.text,
              });
            }
          }}
          type="checkbox"
          value={choice.value}
        />
        <span>{choice.text}</span>
        {field.isRequired && "*"}
      </div>
      {errorMessage && (
        <span className="registerFormErrorMessage">This field cannot be empty!</span>
      )}
    </React.Fragment>
  ));
}

export default Checkbox;
