import React, { Component } from "react";

class SocialSidebar extends Component {
  render() {
    return (
      <div id="socialSidebar">
        <a
          className="socialSidebarIcon"
          href="https://www.facebook.com/SeascapeEdition/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f" />
        </a>
        <a
          className="socialSidebarIcon"
          href="https://www.instagram.com/seascapeedition/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
        <a
          className="socialSidebarIcon"
          href="https://twitter.com/SeascapeEdition"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          className="socialSidebarIcon"
          href="https://www.youtube.com/c/SeascapeEdition"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube" />
        </a>
      </div>
    );
  }
}

export default SocialSidebar;
