import React from "react";
import TwitterModal from "components/TwitterModal";
import { compose } from "redux";
import { connect } from "react-redux";
import jsonp from "jsonp";

export default Component => {
  class WithTwitterModal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        twit: null,
      };
    }

    setTwitterUrl = url =>
      jsonp(`https://publish.twitter.com/oembed?url=${url}`, {}, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          this.setState({ twit: data.html }, () => {
            const twitterEmbedScript = document.getElementById("twitter-embed");
            if (twitterEmbedScript) {
              twitterEmbedScript.remove();
            }
            const twitterScript = document.createElement("script");
            twitterScript.id = "twitter-embed";
            twitterScript.innerHTML = "twttr.widgets.load()";
            document.body.append(twitterScript);
          });
        }
      });

    closeModal = () => this.setState({ twit: "" });

    render() {
      return (
        <React.Fragment>
          <Component {...this.props} setTwitterUrl={this.setTwitterUrl} />
          {this.state.twit ? (
            <TwitterModal twit={this.state.twit} closeModal={this.closeModal} />
          ) : null}
        </React.Fragment>
      );
    }
  }
  const mapStateToProps = state => {
    return { collections: state.posts };
  };

  return compose(
    connect(
      mapStateToProps,
      null
    )
  )(WithTwitterModal);
};
