import React, { Component } from "react";

import loader from "assets/loader.svg";

class FullScreenLoader extends Component {
  render() {
    if (this.props.loading) {
      return null;
    }

    return (
      <div id="fullScreenLoader">
        <div className="fullScreenLoaderContainer">
          <img src={loader} alt="loader" />
        </div>
      </div>
    );
  }
}

export default FullScreenLoader;
