import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import routes from "constants/routes";

class OwnersGrid extends Component {
  render() {
    const { content } = this.props;

    if (
      !(
        content &&
        content.image_one &&
        content.image_two &&
        content.image_three &&
        content.seascape_owners_section.title &&
        content.seascape_owners_section.content &&
        content.ceo_section.title &&
        content.ceo_section.content
      )
    ) {
      return null;
    }

    return (
      <div id="ownersGrid">
        <div className="row no-gutters">
          <div className="col-mdlg-4">
            <div
              className="ownersImageContainer"
              style={{
                background: `url(${content && content.image_one})`,
              }}
            />
          </div>
          <div className="col-mdlg-4">
            <div className="impressionTextContainer">
              <h2 className="ownersGridTitle">FOR COMMUNITY</h2>
              <h3 className="ownersGridSubtitle">
                {content && content.seascape_owners_section.title}
              </h3>
              <p className="ownersGridContent">
                {content && content.seascape_owners_section.content}
              </p>
              <div className="row">
                <div className="col-12 col-md-8">
                  <Link
                    to={routes.COMMUNITY}
                    className="btn btn-outline-light ownersGridDiscoverButton"
                  >
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-mdlg-4">
            <div
              className="ownersImageContainer"
              style={{
                background: `url(${content && content.image_two})`,
              }}
            />
          </div>
        </div>

        {/* this is ceo row, uncoment this when there will be some content */}
        {/* <div className="row no-gutters">
          <div className="col-mdlg-4 ownersGridLightColumn">
            <div className="impressionTextContainer">
              <h2 className="ownersGridTitleDark">SEASCAPE CEO</h2>
              <h3 className="ownersGridSubtitleDark">{content && content.ceo_section.title}</h3>
              <p className="ownersGridContent">{content && content.ceo_section.content}</p>
            </div>
          </div>
          <div className="col">
            <div
              className="ownersImageContainer"
              style={{
                background: `url(${content && content.image_three})`,
              }}
            />
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(OwnersGrid);
