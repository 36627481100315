import React, { Component } from "react";

import { Link } from "react-router-dom";
import date from "utils/date";
import withCollections from "higherOrderComponents/withCollections";

class WideCardEvents extends Component {
  render() {
    const { event } = this.props;
    const flag = `/assets/flags/${event.country.value}.svg`;

    const day = date.getDay(event.start_date);
    const month = date.getMonth(event.start_date);

    return (
      <div className="row">
        <Link className="linkEventCard" to={"/events/" + event.id}>
          <div className="wideCardEventsContainer">
            <div className="row rowContentPosition">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div
                  className="wideCardEventsImg"
                  style={{
                    background: `url(${
                      event.thumbnail_photo && event.thumbnail_photo.large ? event.thumbnail_photo.large : event.featured_photo.large
                    })`,
                  }}
                >
                  <div className="date">
                    <div className="innerDateWrapper">
                      <span className="dateNumber">{day}</span>
                      <span className="dateMonth">{month}</span>
                    </div>
                  </div>
                </div>
                <button className="imgButton">Read more</button>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="wideCardEventsContent">
                  <div className="col-12">
                    <div className="countryContainer">
                      <img src={flag} alt="country name" className="countryFlag" />
                      <span className="countryName">
                        {event.country.label}, {event.city}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <h3>{event.title}</h3>
                  </div>
                  <div className="col-12">
                    <p>
                      {event.intro && event.intro.length > 190
                        ? event.intro.slice(0, 187) + "..."
                        : event.intro}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default withCollections(WideCardEvents);
