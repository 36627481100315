import React, { Component } from "react";

import { Helmet } from "react-helmet";
import NewsContent from "../../components/NewsContent";
import NotFound from "../NotFound";
import SingleNewsHero from "../../components/Heros/SingleNewsHero";
import date from "utils/date";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class SingleNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.newsId) {
      let newsId = nextProps.match.params.id;
      // Fix newsletter bug
      if (newsId === 'first-36-the-ultimate-ballance-9007') {
        newsId = 'first-36-the-ultimate-balance-9007';
      }
      return { newsId };
    }
    return null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const news =
      this.props.collections.fetchedCollections.news &&
      this.props.collections.news[this.state.newsId];

    if (!news) {
      return <NotFound />;
    }

    const publishDate = date.getPublishDate(news.post_date);

    const editedPublishDate =
      publishDate.slice(0, publishDate.lastIndexOf(" ")) +
      " '" +
      publishDate.slice(publishDate.lastIndexOf(" ") + 1);

    return (
      <div className="singleNewsContainer">
        <Helmet>
          <title> Seascape Edition | News - {news.title}</title>
          <meta name="description" content={news.intro} />
        </Helmet>

        <section>
          <SingleNewsHero news={news} />
        </section>

        <div className="container">
          <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
            <div className="publishDate">
              <span>
                Published {editedPublishDate}{" "}
                {news.author ? `by ${news.author}` : null}
              </span>
            </div>
          </div>
        </div>

        <section id="navbarTrigger" className="singleNewsContentContainer">
          <NewsContent news={news} />
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(SingleNews));
