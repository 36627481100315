import React from "react";

function Input({ field, data, onChange, errorMessage }) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          className={`registerFormInput${errorMessage ? " error" : ""}`}
          name={field.id}
          onChange={e => onChange({ ...data, [field.id]: e.target.value })}
          placeholder={field.label}
          required={field.isRequired}
          type={field.type}
        />
        {field.isRequired && "*"}
      </div>
      {errorMessage && (
        <span className="registerFormErrorMessage">This field cannot be empty!</span>
      )}
    </React.Fragment>
  );
}

export default Input;
