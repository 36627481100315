import React, { Component } from "react";

class HorizontalScroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horizontalScroll: null,
      scrollable: false,
      childrenLength: null,
    };
  }

  componentDidMount() {
    this.setState({
      horizontalScroll: document.getElementsByClassName("horizontalScrollSection")[
        this.props.index
      ],
    });

    if (this.props.children.length > 2) {
      this.setState({
        scrollable: true,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    if (nextProps.children.length !== prevState.childrenLength) {
      newState.childrenLength = nextProps.children.length;
    }
    if (prevState.childrenLength > 2) {
      newState.scrollable = true;
    }
    return newState;
  }

  scroll(offset) {
    this.state.horizontalScroll.scroll({
      left: this.state.horizontalScroll.scrollLeft + offset,
      behavior: "smooth",
    });
  }

  render() {
    const offset =
      (document.getElementsByClassName("storyScrollSectionCard")[0] &&
        document.getElementsByClassName("storyScrollSectionCard")[0].clientWidth) + 40 || 0;

    return (
      <div className="horizontalScroll">
        <div className="horizontalScrollSection">
          <div
            className="horizontalScrollSectionOverlayLeft"
            onClick={() => {
              if (this.state.scrollable) {
                this.scroll(-offset);
              } else return null;
            }}
          >
            {this.state.scrollable ? (
              <i className="fas fa-chevron-left fa-2x horizontalScrollArrowLeft" />
            ) : (
              <i className="fas fa-chevron-left fa-2x horizontalScrollArrowLeftDisabled" />
            )}
          </div>
          {this.props.children}
          <div
            className="horizontalScrollSectionOverlayRight"
            onClick={() => {
              if (this.state.scrollable) {
                this.scroll(offset);
              } else return null;
            }}
          >
            {this.state.scrollable ? (
              <i className="fas fa-chevron-right fa-2x horizontalScrollArrowRight" />
            ) : (
              <i className="fas fa-chevron-right fa-2x horizontalScrollArrowRightDisabled" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HorizontalScroll;
