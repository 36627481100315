import React, { Component } from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import { Helmet } from "react-helmet";
import NotFound from "../NotFound";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class SingleOwnersClubs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ownersClubId: null,
    };

    api.pullOwnersClubs();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (nextProps.match.params.id !== prevState.ownersClubId) {
      newState.ownersClubId = nextProps.match.params.id;
    }

    return newState;
  }

  render() {
    let ownersClubs = this.props.collections.fetchedCollections.ownersClubs && {
      ...this.props.collections.ownersClubs.countries[this.state.ownersClubId],
      id: this.state.ownersClubId,
    };

    if (!ownersClubs.title) {
      return <NotFound />;
    }

    return (
      <div id="about">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Seascape Edition | {ownersClubs.title}</title>
          <meta
            name="description"
            content="We are here to inspire people to become sailors. More precisely, to expand their comfort zone by connecting to the wind, the sea and fellow sailors."
          />
        </Helmet>
        <section>
          <div
            className="aboutHero"
            style={{
              background: `url(${ownersClubs.featured_photo.original})`,
            }}
          >
            <div className="heroOverlay" />
            <Breadcrumbs />
          </div>
        </section>
        <section id="navbarTrigger">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-md-12 offset-md-0">
                <div id="wordpressContent">
                  <h2 className="aboutTitle">{ownersClubs.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ownersClubs.content || null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(SingleOwnersClubs));
