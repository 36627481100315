import React, { Component } from "react";

import CommunityContent from "../../components/CommunityContent";
import CommunityHero from "../../components/Heros/CommunityHero";
import { Helmet } from "react-helmet";
import api from "../../api/posts";
import withCollections from "higherOrderComponents/withCollections";
import { withRouter } from "react-router-dom";

class Community extends Component {
  constructor(props) {
    super(props);
    api.pullCommunity();
  }
  render() {
    const community =
      this.props.collections.fetchedCollections.community &&
      this.props.collections.community;

    return (
      <div id="community">
        <Helmet>
          <title> Seascape Edition | Community</title>
          <meta
            name="description"
            content="The fastest-growing community of recreational sailors connected by the love of the sea, wind, challenging adventures and camaraderie."
          />
        </Helmet>
        <section>
          <CommunityHero />
        </section>
        <section id="navbarTrigger">
          <CommunityContent community={community} />
        </section>
      </div>
    );
  }
}

export default withRouter(withCollections(Community));
