import { addCollectionToFetched, addDocumentsToCollection } from "redux/actions";

import _ from "lodash";
import postTypes from "constants/postTypes";
import store from "redux/store";
import { wpGet } from "api/wordpress/core";

function pullAbout() {
  return wpGet(postTypes.ABOUT).then(res => {
    let about = res.data;
    addDocumentsToCollection(about, postTypes.ABOUT, store.dispatch);
    addCollectionToFetched(postTypes.ABOUT, store.dispatch);
    return about;
  });
}

function pullEvents() {
  return wpGet(postTypes.EVENTS).then(res => {
    let events = res.data;
    events.all = { ...events.live, ...events.recent, ...events.upcoming, ...events.past };
    addDocumentsToCollection(events, postTypes.EVENTS, store.dispatch);
    addCollectionToFetched(postTypes.EVENTS, store.dispatch);
    return events;
  });
}

function pullNews() {
  return wpGet(postTypes.NEWS).then(res => {
    let news = res.data;
    addDocumentsToCollection(news, postTypes.NEWS, store.dispatch);
    addCollectionToFetched(postTypes.NEWS, store.dispatch);
    return news;
  });
}

function pullStories() {
  return wpGet(postTypes.STORIES).then(res => {
    let stories = res.data;
    addDocumentsToCollection(stories, postTypes.STORIES, store.dispatch);
    addCollectionToFetched(postTypes.STORIES, store.dispatch);
    return stories;
  });
}

function pullThemeSettings() {
  return wpGet(postTypes.THEME_SETTINGS).then(res => {
    let themeSettings = res.data;
    addDocumentsToCollection(themeSettings, postTypes.THEME_SETTINGS, store.dispatch);
    addCollectionToFetched(postTypes.THEME_SETTINGS, store.dispatch);
    return themeSettings;
  });
}

function pullTwitterFeed() {
  return wpGet(postTypes.TWITTER).then(res => {
    let tweets = res.data;
    addDocumentsToCollection(tweets, postTypes.TWITTER, store.dispatch);
    addCollectionToFetched(postTypes.TWITTER, store.dispatch);
    return tweets;
  });
}

function pullTeams() {
  return wpGet("entries").then(res => {
    let teams = res.data;
    addDocumentsToCollection(teams, postTypes.TEAMS, store.dispatch);
    addCollectionToFetched(postTypes.TEAMS, store.dispatch);
    return teams;
  });
}

function pullCommunity() {
  return wpGet(postTypes.COMMUNITY).then(res => {
    let community = res.data;
    addDocumentsToCollection(community, postTypes.COMMUNITY, store.dispatch);
    addCollectionToFetched(postTypes.COMMUNITY, store.dispatch);
    return community;
  });
}

function pullKnowledgeBase() {
  return wpGet(_.kebabCase(postTypes.KNOWLEDGE_BASE)).then(res => {
    let community = res.data;
    addDocumentsToCollection(community, postTypes.KNOWLEDGE_BASE, store.dispatch);
    addCollectionToFetched(postTypes.KNOWLEDGE_BASE, store.dispatch);
    return community;
  });
}

function pullOwnersClubs() {
  return wpGet(_.kebabCase(postTypes.OWNERS_CLUBS)).then(res => {
    let community = res.data;
    addDocumentsToCollection(community, postTypes.OWNERS_CLUBS, store.dispatch);
    addCollectionToFetched(postTypes.OWNERS_CLUBS, store.dispatch);
    return community;
  });
}

function pullVlog() {
  return wpGet(_.kebabCase(postTypes.VLOG)).then(res => {
    let community = res.data;
    addDocumentsToCollection(community, postTypes.VLOG, store.dispatch);
    addCollectionToFetched(postTypes.VLOG, store.dispatch);
    return community;
  });
}

function pullSubscribe() {
  return wpGet(postTypes.SUBSCRIBE).then(res => {
    let subscribe = res.data;
    addDocumentsToCollection(subscribe, postTypes.SUBSCRIBE, store.dispatch);
    addCollectionToFetched(postTypes.SUBSCRIBE, store.dispatch);
    return subscribe;
  });
}

function pullLanding() {
  return wpGet(postTypes.LANDING).then(res => {
    let landing = res.data;
    addDocumentsToCollection(landing, postTypes.LANDING, store.dispatch);
    addCollectionToFetched(postTypes.LANDING, store.dispatch);
    return landing;
  });
}

function pullLegalNotice() {
  return wpGet(postTypes.LEGAL).then(res => {
    let legalNotice = res.data;
    addDocumentsToCollection(legalNotice, postTypes.LEGAL, store.dispatch);
    addCollectionToFetched(postTypes.LEGAL, store.dispatch);
    return legalNotice;
  });
}

function pullWelcomeAboard() {
  return wpGet(_.kebabCase(postTypes.WELCOME_ABOARD)).then(res => {
    let welcomeAboard = res.data;
    addDocumentsToCollection(welcomeAboard, postTypes.WELCOME_ABOARD, store.dispatch);
    addCollectionToFetched(postTypes.WELCOME_ABOARD, store.dispatch);
    return welcomeAboard;
  });
}

function pullProjects() {
  return wpGet(_.kebabCase(postTypes.PROJECTS)).then(res => {
    let projects = res.data;
    addDocumentsToCollection(projects, postTypes.PROJECTS, store.dispatch);
    addCollectionToFetched(postTypes.PROJECTS, store.dispatch);
    return projects;
  });
}

function pullBoats() {
  return wpGet(_.kebabCase(postTypes.BOATS)).then(res => {
    let projects = res.data;
    addDocumentsToCollection(projects, postTypes.BOATS, store.dispatch);
    addCollectionToFetched(postTypes.BOATS, store.dispatch);
    return projects;
  });
}

function pullBoatModels() {
  return wpGet(_.kebabCase(postTypes.BOAT_MODELS)).then(res => {
    let projects = res.data;
    addDocumentsToCollection(projects, postTypes.BOAT_MODELS, store.dispatch);
    addCollectionToFetched(postTypes.BOAT_MODELS, store.dispatch);
    return projects;
  });
}

function pullTags() {
  return wpGet(_.kebabCase(postTypes.TAGS)).then(res => {
    let projects = res.data;
    addDocumentsToCollection(projects, postTypes.TAGS, store.dispatch);
    addCollectionToFetched(postTypes.TAGS, store.dispatch);
    return projects;
  });
}

function pullPopup() {
  return wpGet(postTypes.POPUP).then(res => {
    let projects = res.data;
    addDocumentsToCollection(projects, postTypes.POPUP, store.dispatch);
    addCollectionToFetched(postTypes.POPUP, store.dispatch);
    return projects;
  });
}


function pullContent() {
  return Promise.all([
    pullPopup(),
    pullLanding(),
    pullEvents(),
    pullStories(),
    pullNews(),
    pullThemeSettings(),
    pullSubscribe(),
    pullTags(),
  ]).then(responses => {
    const events = responses[1];
    const mergedEvents = {
      ...events.live,
      ...events.recent,
      ...events.upcoming,
      ...events.past,
    };

    let masonry = {};

    Object.keys(responses[3])
      .filter(storyId => responses[3][storyId].masonry)
      .map(storyId => ({ [storyId]: responses[3][storyId] }))
      .forEach(story => {
        masonry = Object.assign({}, masonry, story);
      });

    Object.keys(responses[4])
      .filter(newsId => responses[4][newsId].masonry)
      .map(newsId => ({ [newsId]: responses[4][newsId] }))
      .forEach(news => {
        masonry = Object.assign({}, masonry, news);
      });

    masonry = Object.assign({}, masonry, mergedEvents);

    addDocumentsToCollection(masonry, postTypes.MASONRY, store.dispatch);
    addCollectionToFetched(postTypes.MASONRY, store.dispatch);
  });
}

export default { 
  pullContent,
  pullTwitterFeed,
  pullTeams,
  pullAbout,
  pullCommunity,
  pullKnowledgeBase,
  pullOwnersClubs,
  pullVlog,
  pullLegalNotice,
  pullWelcomeAboard,
  pullProjects,
  pullBoats,
  pullBoatModels,
};
